import React, {
	FC, ReactElement,
} from 'react'
import {
	MenuItem,
	Select,
	SelectChangeEvent, styled,
	FormControl, SelectProps,
	FormHelperText, Tooltip, TooltipProps,
} from '@mui/material'
import BasicLabel from './base/BasicLabel.tsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MBAILoadingSpinner from './loaders/MBAILoadingSpinner'
import {getInputPadding} from './utils/baseInputs.ts'

export interface ListItem<T = Record<string, unknown>> {
	id: string
	name: string
	additionalProps?: T // Generic type for additional properties
}

type TooltipPlacementOptions = TooltipProps['placement']

export type BasicSelectProps = SelectProps & {
	onBasicSelectChange?: (value: string | string[]) => void
	label?: string
	value: string | string[]
	list?: (ListItem | string)[]
	nullOption?: boolean
	nullLabel?: string
	loading?: boolean
	mbaiSize?: 'small' | 'medium' | 'large'
	helperText?: string | null
	tooltipPlacement?: TooltipPlacementOptions
	displayLabel?: boolean
	children?: ReactElement | ReactElement[]
	listItemDataCy?: string
	minHeight?: string
	maxHeight?: string
}

// Wrapper component for loading spinner
const LoadingSpinnerIcon: FC = (props) => (
	<MBAILoadingSpinner
		size="xsmall"
		{...props}
	/>
)

// Wrapper component for expand more icon
const ExpandMoreIconWrapper: FC = (props) => (
	<ExpandMoreIcon fontSize="small" {...props} />
)

const MBAIBaseSelect = styled(Select)(({theme}) => ({
	'label + &': {marginTop: theme.spacing(3)},
	'& :disabled': {
		'& .MuiInput:before': {borderBottom: 'none'},
		'& .MuiInput:after': {borderBottom: 'none'},
	},
	'& div.MuiInputBase-root': {
		padding: 0,
		'& .MuiInputAdornment-positionEnd': {paddingRight: '10px'},
	},
	'& input.MuiSelect-nativeInput': {border: 'none'},
	'&.Mui-focused div.MuiSelect-select': {border: `2px solid ${theme.palette.primary.main}`},
	'div.MuiSelect-select': {
		borderRadius: 6,
		position: 'relative',
		border: '2px solid transparent',
		fontSize: 14,
		width: '100%',
		lineHeight: '1.2rem',
		paddingRight: '36px !important',
		backgroundColor: theme.palette.background.fadedOpaque,
		fontFamily: 'Circular Book',
		'& .MuiSvgIcon-root': {right: '8px'},
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {backgroundColor: theme.palette.divider},
	},
}))

const StyledFormControl = styled(FormControl)(() => ({
	'& .MuiInput-underline:before': {borderBottom: 'none'},
	'& .MuiInput-underline:after': {borderBottom: 'none'},
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {borderBottom: 'none'},
	'& :disabled': {
		'& .MuiInput:before': {borderBottom: 'none'},
		'& .MuiInput:after': {borderBottom: 'none'},
	},
	'& .MuiSvgIcon-root': {right: '8px'},
	'& div.MuiSelect-icon': {
		right: '24px',
		top: 'calc(50% - 7px)',
	},
}))

const BasicSelect: FC<BasicSelectProps> = (
	{
		onBasicSelectChange,
		label,
		value,
		list,
		nullOption = false,
		nullLabel = '',
		loading = false,
		mbaiSize = 'medium',
		helperText,
		tooltipPlacement = 'left',
		displayLabel = true,
		listItemDataCy,
		minHeight,
		maxHeight = '400px',
		children,
		...rest
	},
): ReactElement => {
	const IconComponent = loading ? LoadingSpinnerIcon : ExpandMoreIconWrapper
	const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
		if (onBasicSelectChange) {
			onBasicSelectChange(event.target.value as string)
		}
	}

	return (
		<StyledFormControl
			variant="standard"
			fullWidth
			data-testid="mbai-basic-select"
		>
			{
				displayLabel ? (
					<BasicLabel
						label={label as string}
					/>
				) : null
			}
			<Tooltip
				title={displayLabel ? '' : label}
				placement={tooltipPlacement}
			>
				<MBAIBaseSelect
					id="demo-simple-select"
					value={value}
					onChange={handleSelectChange}
					sx={{'div.MuiSelect-select': {padding: getInputPadding(mbaiSize)}}}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight,
								minHeight,
							},
						},
					}}
					IconComponent={IconComponent}
					{...rest}
				>
					{
						nullOption && !children ? (
							<MenuItem
								className={`${value === 'null' ? 'Mui-selected' : ''}`}
								key="null"
								value="null"
								data-cy={listItemDataCy}
							>
								{nullLabel}
							</MenuItem>
						) : null
					}
					{children || null}
					{
						list && list.length > 0 ? list.map((item) => {
							if (typeof item === 'string') {
								return (
									<MenuItem
										key={item}
										value={item}
										data-cy={listItemDataCy}
									>
										{item}
									</MenuItem>
								)
							}
							return (
								<MenuItem
									key={item.id}
									value={item.id}
									data-cy={listItemDataCy}
								>
									{item.name}
								</MenuItem>
							)
						}) : null
					}
				</MBAIBaseSelect>
			</Tooltip>
			{
				helperText ? (
					<FormHelperText sx={{mt: 1}}>{helperText}</FormHelperText>
				) : null
			}
		</StyledFormControl>
	)
}

export default BasicSelect
