import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './containers/App/App'
import 'mapbox-gl/dist/mapbox-gl.css'
import './index.css'

const main = async () => {
	if (process.env.NODE_ENV === 'development') {
		// const {worker} = require('./mocks/browser')
		// worker.start()
	}

	const rootElement = document.getElementById('root')
	if (!rootElement) throw new Error('Failed to find the root element')
	const root = ReactDOM.createRoot(rootElement)
	root.render(
		<App />,
	)
}
main()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
