import React, {
	StrictMode, FC, SyntheticEvent, BaseSyntheticEvent, ReactElement,
} from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Box,
} from '@mui/material'
import {useCustomTranslation} from '../hooks/useCustomTranslation.ts'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
	children?: ReactElement,
	footerAction?: ReactElement,
    onClose: (event?: SyntheticEvent | BaseSyntheticEvent) => void,
    title: string,
    open: boolean,
	wrappers?: boolean,
	handleCloseFunctions?: ()=> void,
	closeButtonLabel?: string,
	maxWidth?: string,
	width?: string,
	height?: string,
}

const BasicModal: FC<Props> = (
	{
		children,
		onClose,
		title,
		open,
		footerAction,
		wrappers = true,
		handleCloseFunctions,
		closeButtonLabel,
		maxWidth,
		width = 'auto',
		height = 'auto',
	},
): ReactElement => {
	const [t] = useCustomTranslation('common')

	const handleClose = () => {
		if (handleCloseFunctions) handleCloseFunctions()
		onClose()
	}

	return (
		<StrictMode>
			<Dialog
				onClose={handleClose}
				open={open}
				data-testid="mbai-main-basic-modal"
				data-cy="dialog-basic-modal"
				sx={{
					'& .MuiPaper-root': {
						maxWidth: maxWidth || '600px',
						width,
					},
					'& .MuiDialogActions-root': {
						borderTop: 1,
						borderColor: 'divider',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						borderBottom: 1,
						borderColor: 'divider',
					}}
				>
					<DialogTitle
						sx={{maxWidth: '90%'}}
						noWrap
						data-cy="dialog-title-basic-modal"
					>
						{title}
					</DialogTitle>
					<IconButton
						sx={{
							mr: 1,
							mt: 1,
							height: '45px',
							width: '45px',
						}}
						onClick={() => onClose()}
						data-testid="mbai-main-basic-modal-close-icon"
						data-cy="mbai-main-basic-modal-close-icon"
					>
						<CloseIcon
							sx={{color: 'text.disabled'}}
						/>
					</IconButton>
				</Box>
				{
					wrappers ? (
						<DialogContent
							sx={{
								height,
								overflowY: 'auto',
							}}
						>
							{children}
						</DialogContent>
					) : (
						children
					)
				}
				<DialogActions>
					{footerAction}
					<Button
						onClick={handleClose}
						name="view-save-update-form-cancel"
						data-testid="mbai-main-basic-modal-close-button"
						data-cy="dialog-cancel-button-basic-modal"
					>
						{closeButtonLabel || t('cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</StrictMode>
	)
}

export default BasicModal
