import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {AlertColor} from '@mui/material'

type Props = {
    open: boolean,
    message: string,
    severity: AlertColor | undefined,
    action: string,
}

const initialState: Props = {
	open: false,
	message: '',
	severity: undefined,
	action: '',
}

export const snackbar = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		showSnackbar: (state, action: PayloadAction<{
            message: string;
            severity: AlertColor | undefined;
            action: string
        }>) => ({
			...state,
			message: action.payload.message,
			severity: action.payload.severity,
			open: true,
			action: action.payload.action,
		}),
		hideSnackbar: (state) => ({
			...state,
			message: '',
			severity: undefined,
			open: false,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {
	showSnackbar, hideSnackbar,
} = snackbar.actions
export default snackbar.reducer
