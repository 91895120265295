import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {
	getUniqueValuesFromArray, getUrlParams,
} from '../../util-functions'
import {
	ViewsSignalInterface, OverlayedViewSignalInterface, SignalInterface,
	SliceStateProps,
} from '../../shared/interfaces/signal'
import {GridRowSelectionModel} from '@mui/x-data-grid'
import {HorizontalLineInterface} from '../../shared/interfaces/view'

type InitialStateProps = SliceStateProps

const initialState: InitialStateProps = {
	all: [],
	active: [],
	overlayed: [],
	activeSignalsViews: [],
	order: [],
	url: '',
}

export const signals = createSlice({
	name: 'signals',
	initialState,
	reducers: {
		setAllSignals: (state, action: PayloadAction<SignalInterface[]>) => ({
			...state,
			all: action.payload,
		}),
		setGlobalViewSignals: (state, action: PayloadAction<ViewsSignalInterface[] | OverlayedViewSignalInterface[]>) => ({
			...state,
			activeSignalsViews: action.payload,
		}),
		addGlobalViewSignals: (state, action: PayloadAction<ViewsSignalInterface>) => ({
			...state,
			activeSignalsViews: [
				...state.activeSignalsViews,
				action.payload,
			],
		}),
		removeGlobalViewSignals: (state, action: PayloadAction<string>) => {
			const newActiveSignals = state.activeSignalsViews.filter((item: ViewsSignalInterface | OverlayedViewSignalInterface) => item.signal !== action.payload)
			return {
				...state,
				activeSignalsViews: newActiveSignals,
			}
		},
		setGlobalActiveSignals: (state, action: PayloadAction<OverlayedViewSignalInterface[] | SignalInterface[] | ViewsSignalInterface[] | GridRowSelectionModel | null>) => {
			if (action.payload) {
				const activeSignals = action.payload.map((item) => {
					if (!item) return null
					let signal
					if (typeof item === 'string') {
						signal = state.all.find((i: SignalInterface) => i.id === item)
					} else if ((item as SignalInterface).id) {
						signal = state.all.find((i: SignalInterface) => i.id === (item as SignalInterface).id)
					} else if ((item as ViewsSignalInterface).signal) {
						// Handles selected signals from views
						signal = state.all.find((i: SignalInterface) => i.id === (item as ViewsSignalInterface).signal)
					}
					return signal
				})
					.filter((item) => item !== undefined || item !== null)
				if (activeSignals && activeSignals.length > 0 && activeSignals[0]) {
					const signalsURL = getUrlParams(activeSignals.map((item) => (item as SignalInterface).id), 'signal')
					return {
						...state,
						active: getUniqueValuesFromArray(activeSignals) as SignalInterface[],
						url: signalsURL,
						overlayed: activeSignals.map((item) => ({signal: (item as SignalInterface).id})),
					}
				}
			}
			return {
				...state,
				active: action.payload as SignalInterface[],
			}
		},
		setSignalHeight: (state, action: PayloadAction<{ id: string, height: number }>) => ({
			...state,
			activeSignalsViews: state.activeSignalsViews.map((item: ViewsSignalInterface | OverlayedViewSignalInterface) => {
				if (item.signal === action.payload.id) {
					return {
						...item,
						graph_height: action.payload.height,
					}
				}
				return item
			}),
		}),
		setSignalHorizontalLines: (state, action: PayloadAction<{ id: string, lines: HorizontalLineInterface[] }>) => ({
			...state,
			activeSignalsViews: state.activeSignalsViews.map((item: ViewsSignalInterface | OverlayedViewSignalInterface) => {
				if (item.signal === action.payload.id) {
					return {
						...item,
						horizontal_lines: action.payload.lines,
					}
				}
				return item
			}),
		}),
		reorderActiveViewSignal: (state, action: PayloadAction<string[]>) => {
			const newActiveSignalsViews = action.payload.map((item: string) => state.activeSignalsViews.find((i) => i.signal === item))
			return {
				...state,
				activeSignalsViews: newActiveSignalsViews as ViewsSignalInterface[],
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setAllSignals,
	setGlobalActiveSignals,
	setSignalHeight,
	setGlobalViewSignals,
	reorderActiveViewSignal,
	addGlobalViewSignals,
	removeGlobalViewSignals,
	setSignalHorizontalLines,
} = signals.actions
export default signals.reducer
