import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {AnomalyWithReasonInterface} from '../../shared/interfaces/anomaly'
import {AnomalyProgramInterface} from '../../shared/interfaces/program'

const initialStateDetails: AnomalyProgramInterface = {
	id: '',
	name: '',
}

// const initialState: AnomalyInterface = {
// 	id: '',
// 	name: '',
// 	description: '',
// 	counter: 0,
// 	created_on: '',
// 	created_by: '',
// 	detection_reason: '',
// 	user_feedback: null,
// 	last_changed_on: null,
// 	last_changed_by: null,
// 	machine: '',
// 	program: initialStateDetails,
// 	batch: initialStateDetails,
// 	signal: initialStateDetails,
// 	step: 0,
// 	value: '',
// 	score: 0,
// 	window_t_start: '',
// 	window_t_end: '',
// 	window_t_start_relative: '',
// 	window_t_end_relative: '',
// 	discussion: null,
// 	n_comments: null,
// 	confidence: null,
// 	reason_for_confidence: null,
// }

const initialState: AnomalyWithReasonInterface = {
	ad_anomaly_id: 0,
	created_at: '',
	description: '',
	end: '',
	id: '',
	reason: '',
	score: 0,
	start: '',
	step: '',
	updated_at: '',
	batch: initialStateDetails,
	machine: initialStateDetails,
	program: initialStateDetails,
	machine_sub_system: initialStateDetails,
	signal: initialStateDetails,
	end_relative: '',
	start_relative: '',
}

export const anomalies = createSlice({
	name: 'anomalies',
	initialState: {current: initialState},
	reducers: {
		setCurrentAnomaly: (state, action: PayloadAction<AnomalyWithReasonInterface>) => ({
			...state,
			current: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setCurrentAnomaly} = anomalies.actions
export default anomalies.reducer
