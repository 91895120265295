import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {getUrlParams} from '../../util-functions'
import {ProgramInterface} from '../../shared/interfaces/program'
import {GridRowSelectionModel} from '@mui/x-data-grid'

type InitialStateType = {
	selected: string[],
	url: string,
}

const initialState: InitialStateType = {
	selected: [],
	url: '',
}

export const programs = createSlice({
	name: 'programs',
	initialState,
	reducers: {
		setGlobalSelectedPrograms: (state, action: PayloadAction<ProgramInterface[] | string[] | GridRowSelectionModel>) => {
			if (action.payload && action.payload[0]) {
				if (action.payload[0].hasOwnProperty('id')) {
					const programsIds = action.payload.map((item) => (item as ProgramInterface).id)
					const programsURL = getUrlParams(programsIds, 'program')
					return {
						...state,
						selected: programsIds,
						url: programsURL,
					}
				}
				const programsURL = getUrlParams(action.payload, 'program')
				return {
					...state,
					selected: action.payload as string[],
					url: programsURL,
				}
			}
			return {
				...state,
				selected: [],
				url: '',
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {setGlobalSelectedPrograms} = programs.actions
export default programs.reducer
