import React, {
	FC, ReactElement,
} from 'react'
import {
	Box, Skeleton,
} from '@mui/material'
import BasicDashboardContainer from '../../BasicDashboardContainer'
import CardViewLoader from '../CardView'

const LinesOverviewLoader: FC = (): ReactElement => {
	const height = '40px'
	const br = '5px'
	return (
		<BasicDashboardContainer>
			<Box
				data-testid="mbai-main-line-overview-page-loader"
				sx={{
					width: '100%',
					height: '100vh',
				}}
			>
				<Box>
					<Skeleton
						sx={{
							width: '100%',
							maxWidth: '400px',
						}}
					/>
				</Box>
				<Box
					sx={{
						pt: 3,
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Box sx={{display: 'flex'}}>
						<Skeleton
							variant="rounded"
							sx={{
								width: height,
								height,
								borderRadius: `${br} 0 0 ${br}`,
								border: 1,
								borderColor: 'divider',
							}}
						/>
						<Skeleton
							variant="rounded"
							sx={{
								width: height,
								height,
								borderRadius: `0 ${br} ${br} 0`,
								border: 1,
								borderColor: 'divider',
								borderLeft: 'none',
							}}
						/>
						<Skeleton
							variant="rounded"
							sx={{
								width: height,
								height,
								ml: 2,
							}}
						/>
						<Skeleton
							variant="rounded"
							sx={{
								width: '500px',
								height,
								ml: 2,
							}}
						/>
					</Box>
					<Skeleton
						variant="rounded"
						sx={{
							width: '200px',
							height,
						}}
					/>
				</Box>
				<Box
					sx={{
						pt: 3,
						display: 'flex',
					}}
				>
					<CardViewLoader />
				</Box>
			</Box>
		</BasicDashboardContainer>
	)
}

export default LinesOverviewLoader
