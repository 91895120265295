import {Box} from '@mui/material/'
import {FC} from 'react'
import factorImage201 from '../images/on-logic-mbai-two.png'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'
import ConnectionOptionCard from './ConnectionOptionCard'

const ConnectionOptionCardTable: FC = () => {
	const [t] = useCustomTranslation('edge_api')
	const options = [
		{
			image: factorImage201,
			text: 'OnLogic Factor 201 with MBAI Edge OS',
			alt: 'Onlogic Factor 201',
			value: 'factor_201',
		},
		{
			text: t('your_own_mqtt_publisher'),
			value: 'own_mqtt_publisher',
		},
		{
			text: t('deploy_edge_in_your_own_infrastructure'),
			value: 'montblanc_edge_container_own_infraestructure',
		},
	]

	return (
		<Box
			sx={{
				display: 'flex',
				gap: 2,
				justifyContent: 'center',
				alignItems: 'center',
				// flexWrap: 'wrap',
				mb: 2,
			}}
		>
			{options.map((option) => (
				<ConnectionOptionCard
					key={option.value}
					value={option.value}
					image={option.image}
					text={option.text}
					alt={option.alt}
				/>
			))}

		</Box>
	)
}

export default ConnectionOptionCardTable
