import React, {FC} from 'react'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import {
	Box, Divider, LinearProgress, Typography,
} from '@mui/material'
import {useGetEquipmentsWithSearchQuery} from '../../../apis/base.ts'
import ErrorBoundary from '../../../shared/components/ErrorBoundary.tsx'
import CardViewError from '../../../shared/components/errors/CardView.tsx'
import EquipmentCard from '../../Dashboards/LinesOverview/equipments/EquipmentCard.tsx'
import HorizonatlScrollingContainer from '../../../shared/components/HorizontalScrollingContainer.tsx'

interface SearchErpBatchResultsProps {
    searchTerm: string;
}

const SearchEquipmentResults: FC<SearchErpBatchResultsProps> = (
	{searchTerm},
) => {
	const [t] = useCustomTranslation('common')
	const {
		data: searchEquipmentResults,
		error: searchEquipmentError,
		isFetching: searchEquipmentIsFetching,
	} = useGetEquipmentsWithSearchQuery({search: searchTerm}, {skip: !searchTerm})
	return (
		<Box sx={{p: 1}}>
			{searchEquipmentIsFetching ? (
				<Box>
					<Divider>
						<Typography
							variant="h6"
							color="text.secondary"
							sx={{mb: 1}}
						>
							{t('appbar_search_equipment.search_running')}
						</Typography>
					</Divider>
					<LinearProgress
						sx={{width: '100%'}}
					/>
				</Box>
			) : searchEquipmentResults && searchEquipmentResults.length === 0 && !searchEquipmentError ? (
				<Divider>
					<Typography
						variant="h6"
						color="text.secondary"
						align="left"
					>
						{t('appbar_search_equipment.no_results')}
					</Typography>
				</Divider>
			) : searchEquipmentResults && !searchEquipmentError ? (
				<>
					<Divider>
						<Typography
							variant="h6"
							color="text.secondary"
							sx={{mb: 1}}
						>
							{searchEquipmentResults.length}
							{' '}
							{t('appbar_search_equipment.number_found')}
						</Typography>
					</Divider>
					<HorizonatlScrollingContainer>
						{
							searchEquipmentResults.map((equipment) => (
								<Box
									key={equipment.id}
									sx={{
										flex: '0 0 auto',
										width: 300,
										padding: 1,
									}}
								>
									<ErrorBoundary
										fallbackComponent={(
											<CardViewError />
										)}
									>
										<EquipmentCard
											equipment={equipment}
											showDetails={false}
										/>
									</ErrorBoundary>
								</Box>
							))
						}
					</HorizonatlScrollingContainer>
				</>
			) : t('messages.unexpected_error')}
		</Box>
	)
}

export default SearchEquipmentResults
