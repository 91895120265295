import {
	Box, Typography,
} from '@mui/material/'
import {
	ChangeEvent, FC,
} from 'react'
import factor201Image from '../images/on-logic-mbai-two.png'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'
import {
	useDispatch, useSelector,
} from 'react-redux'
import {RootState} from '../../../../../App/store'
import {
	setConnectionDescription, setConnectionName,
} from '../../../redux/slices/edgeApiConnectionFormSlice'
import {useSearchParams} from 'react-router-dom'
import BasicInput from '../../../../../../shared/components/BasicInput'

const AddConnectionUpdateDetails:FC = () => {
	const [t] = useCustomTranslation('edge_api')
	const [searchParams] = useSearchParams()
	const deviceTypeUrl = searchParams.get('device_type')
	const dispatch = useDispatch()
	const {identifier} = useSelector((state: RootState) => state.edgeApiConnectionForm)

	return (
		<>
			<BasicInput
				onChange={(event: ChangeEvent<HTMLInputElement>) => dispatch(setConnectionName(event.target.value))}
				required
				label={t('name')}
				fullWidth
			/>

			<BasicInput
				onChange={(event: ChangeEvent<HTMLInputElement>) => dispatch(setConnectionDescription(event.target.value))}
				required
				label={t('description')}
				fullWidth
			/>
			{deviceTypeUrl === 'factor_201' ? (
				<Box sx={{
					mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',
				}}
				>
					<Typography variant="h3" sx={{mb: 2}}>
						{t('identifier')}
						:
						{identifier || ''}
					</Typography>
					<img
						src={factor201Image}
						alt="Description"
						style={{
							maxWidth: '50%', height: 'auto',
						}}
					/>

				</Box>
			) : null}
		</>
	)
}

export default AddConnectionUpdateDetails
