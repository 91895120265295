import {
	useTranslation, TFunction,
} from 'react-i18next'
import {TOptions} from 'i18next'

export const useCustomTranslation = (namespace : string | string[]): [TFunction, typeof i18n] => {
	const {
		i18n, t,
	} = useTranslation(namespace)

	const customTranslation = (key: string, options?: TOptions) => {
		const translation = t(key, options)

		if (translation === key) {
			throw new Error(`Translation not found for key: ${key}`)
		}
		return translation
	}

	// Returning an array here for flexibility. The customTranslation function can be used multiple times in a component
	return [customTranslation as TFunction, i18n]
}
