import {Theme} from '@mui/material/styles'
import {BasicInputProps} from '../BasicInput'
import {BasicSelectProps} from '../BasicSelect'

export const getBaseInputStyle = (theme: Theme, props: BasicInputProps | BasicSelectProps) => ({
	'label + &': {marginTop: theme.spacing(3)},
	'& fieldset': {
		borderWidth: '0',
		boxShadow: theme.palette.smallBoxShadow,
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
	},
	'& div.MuiInputBase-root': {
		padding: 0,
		'& .MuiInputAdornment-positionEnd': {paddingRight: '10px'},
		'&:hover fieldset': {boxShadow: theme.palette.mediumBoxShadow},
	},
	'& input.MuiSelect-nativeInput': {border: 'none'},
	'& input.MuiInputBase-input, & textarea.MuiInputBase-input, & div.MuiSelect-select': {
		borderRadius: 6,
		position: 'relative',
		fontSize: 14,
		width: '100%',
		height: '1.2rem',
		padding: props?.mbaiSize === 'small' ? '8px 14px' : '12px 14px',
		lineHeight: '1.2rem',
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.divider : '',
		fontFamily: 'Circular Book',
		'& .MuiSvgIcon-root': {right: '8px'},
	},
})

export const getInputPadding = (size: 'small' | 'medium' | 'large') => {
	switch (size) {
		case 'small':
			return '6px 14px'
		case 'medium':
			return '10px 14px'
		case 'large':
			return '16px 14px'
		default:
			return '10px 14px'
	}
}
