import {FC} from 'react'
import AnomalyTable from './Components/AnomalyTable'
import BasicDashboardContainer from '../../../shared/components/BasicDashboardContainer'

const AnomalyComponent:FC = () => (
	<BasicDashboardContainer data-testid="basic-dashboard-container-anomaly-table">
		<AnomalyTable />
	</BasicDashboardContainer>
)

export default AnomalyComponent
