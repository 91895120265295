import {
	FC, ReactElement,
} from 'react'
import {MqttConnectionEdgeApiInterface} from '../../../../../../shared/interfaces/edgeapi/mqttConnectionEdgeApi'
import {
	Grid, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'

type Props = {
    mqttData: MqttConnectionEdgeApiInterface;
};

const MQTTDataCard: FC<Props> = ({mqttData}): ReactElement => {
	const [t] = useCustomTranslation('edge_api')
	const fields = [
		{
			label: t('name'), value: mqttData.name,
		},
		{
			label: t('description'), value: mqttData.description,
		},
		{
			label: 'MQTT Host', value: mqttData.mqtt_host,
		},
		{
			label: 'MQTT Port', value: mqttData.mqtt_port,
		},
		{
			label: 'MQTT Username', value: mqttData.mqtt_username,
		},
		{
			label: 'MQTT Shared Access Key', value: mqttData.mqtt_shared_access_key,
		},
		{
			label: 'MQTT Topic', value: mqttData.mqtt_topic,
		},
		{
			label: 'MQTT QOS', value: mqttData.mqtt_qos,
		},
		{
			label: 'MQTT Version', value: mqttData.mqtt_version,
		},
		{
			label: 'MQTT SSL Enabled', value: mqttData.mqtt_ssl_enabled ? 'Yes' : 'No',
		},
	]

	return (
		<Grid container spacing={2}>
			{fields.map((field) => (
				<Grid item xs={12} key={field.label} container>
					<Grid item xs={4}>
						<Typography variant="body1">
							{field.label}
							:
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography
							variant="body1"
							sx={{
								whiteSpace: 'normal', overflowWrap: 'break-word',
							}}
						>
							{field.value}
						</Typography>
					</Grid>
				</Grid>
			))}
		</Grid>

	)
}

export default MQTTDataCard
