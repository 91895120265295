import {FC} from 'react'
import {Skeleton} from '@mui/material'

interface GlobalIconLoaderProps {
	item: number
	horizontal?: boolean
}

const GlobalIconLoader: FC<GlobalIconLoaderProps> = ({
	item,
	horizontal = true,
}) => (
	<Skeleton
		data-testid="mbai-global-icon-loader"
		variant="rounded"
		sx={{
			height: '32px',
			width: '32px',
			ml: horizontal ? 3 : 0,
			mb: horizontal ? 0 : 3,
		}}
		key={item}
	/>
)

export default GlobalIconLoader
