import React, {
	BaseSyntheticEvent, FC, ReactElement, StrictMode, useEffect, useState,
} from 'react'
import {
	Badge,
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	Popover, Tooltip,
	Typography,
} from '@mui/material'
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import {
	useGetNotificationsMutation, useUpdateNotificationAllReadMutation,
} from '../../../apis/base.ts'
import NotificationListItem from './NotificationListItem'
import {useSnackbar} from '../../../shared/hooks/useSnackbar'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CloseIcon from '@mui/icons-material/Close'
import {NotificationInterface} from '../../../shared/interfaces/notification'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'

const AppbarNotifications: FC = (): ReactElement => {
	const [t] = useCustomTranslation('common')
	const [tSettings] = useCustomTranslation('settings')
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [unreadNotificationsCount, setUnreadNotificationsCount] = useState<number>(0)
	const [getNotifications, {
		data, isLoading, isError,
	}] = useGetNotificationsMutation()
	const [updateReadAll] = useUpdateNotificationAllReadMutation()
	const [showNotification] = useSnackbar()
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	const [notifications, setNotifications] = useState<NotificationInterface[]>([])

	const handleClick = (event: BaseSyntheticEvent): void => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const updateUnreadNotificationsCount = (newReadStatus: boolean): void => {
		if (newReadStatus) {
			setUnreadNotificationsCount(Math.max(unreadNotificationsCount - 1, 0))
		} else {
			setUnreadNotificationsCount(unreadNotificationsCount + 1)
		}
	}

	useEffect(() => {
		if (data) {
			setUnreadNotificationsCount(data.filter((notification) => !notification.read_by_current_user).length)
			setNotifications(data)
		}
	}, [data])

	useEffect(() => {
		if (open) {
			getNotifications()
		}
	}, [open])

	const setAllNotificationsAsRead = () => {
		setNotifications(notifications.map((notification) => ({
			...notification, read_by_current_user: true,
		})))
	}

	const handleClickReadAll = () => {
		updateReadAll({readByCurrentUser: true}).unwrap()
			.then(() => {
				showNotification({
					message: t('messages.successful_update'),
					severity: 'success',
				})
				setUnreadNotificationsCount(0)
				setAllNotificationsAsRead()
			}).catch(() => {
				showNotification({
					message: t('messages.unexpected_error'),
					severity: 'error',
				})
			})
	}

	return (
		<StrictMode>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					ml: 1,
				}}
				data-testid="mbai-main-appbar-notifications"
				className="selectorNotifications"
				id="mbai-main-appbar-notifications"
			>
				<IconButton
					onClick={handleClick}
				>
					<Badge
						badgeContent={unreadNotificationsCount}
						color="primary"
					>
						<NotificationsNoneTwoToneIcon
							color="action"
							data-testid="mbai-main-appbar-notifications-open-button"
							sx={{cursor: 'pointer'}}
						/>
					</Badge>
				</IconButton>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					sx={{mt: 3}}
					data-testid="mbai-main-appbar-notifications-popover"
				>
					<Card
						sx={{maxHeight: '90vh'}}
					>
						<CardHeader
							title={(
								<Typography
									variant="h3"
								>
									{t('appbar.notifications.title')}
								</Typography>
							)}
							action={(
								<Box>
									<Tooltip title={t('appbar.notifications.mark_all_as_read')}>
										<IconButton
											sx={{mr: 1}}
											onClick={handleClickReadAll}
										>
											<MarkEmailReadIcon />
										</IconButton>
									</Tooltip>
									<IconButton
										aria-label="Notifications"
										onClick={handleClose}
										data-testid="mbai-main-appbar-notifications-close-button"
									>
										<CloseIcon />
									</IconButton>
								</Box>
							)}
						/>
						<Divider />
						<CardContent
							sx={{
								p: 0,
								maxHeight: 'calc(90vh - 64px)',
								overflowY: 'scroll',
								'&:last-child': {paddingBottom: '0'},
							}}
						>
							<List
								sx={{
									width: 400,
									py: 0,
									minHeight: '600px',
								}}
							>
								{
									isLoading ? (
										<LinearProgress />
									) : notifications && notifications.length === 0 ? (
										<Box
											sx={{
												height: '70vh',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{textAlign: 'center'}}
											>
												<NotificationImportantIcon
													color="primary"
													fontSize="large"
												/>
												<Typography
													variant="h6"
													color="textSecondary"
													sx={{mt: 1}}
												>
													{tSettings('notifications.no_notifications')}
												</Typography>
											</Box>
										</Box>
									) : notifications ? notifications.map((notification) => (
										<NotificationListItem
											key={notification.id}
											notification={notification}
											onReadUpdate={updateUnreadNotificationsCount}
										/>
									)) : (isError || !notifications) && !isLoading ? (
										<ListItem
											alignItems="flex-start"
										>
											<ListItemText
												primary={t('notifications.no_notifications')}
											/>
										</ListItem>
									) : null
								}
							</List>
						</CardContent>
					</Card>
				</Popover>
			</Box>
		</StrictMode>
	)
}

export default AppbarNotifications
