import React, {FC} from 'react'
import {Box} from '@mui/material'

interface Props {
    image: string,
    label: string,
    imgAltText: string
}

const LanguageFlag: FC<Props> = ({
	image, label, imgAltText,
}): JSX.Element => (
	<Box
		data-testid="mbai-main-language-flag"
		id="language-span"
		sx={{
			display: 'flex',
			alignItems: 'center',
		}}
	>
		<img
			id="language-img"
			src={image}
			alt={imgAltText}
			style={{
				height: '15px',
				width: '20px',
				marginRight: '7px',
			}}
		/>
		<span>{label}</span>
	</Box>
)

export default LanguageFlag
