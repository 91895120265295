import React from 'react'
import {Typography} from '@mui/material'

const FormTitle = ({label}: {label: string}) => (
	<Typography
		variant="h1"
		data-testid="mbai-auth-form-title"
		sx={{
			color: 'text.primary',
			mb: 0,
			mt: 0,
		}}
	>
		{label}
	</Typography>
)

export default FormTitle
