export const timeRangeOptions = [
	{
		id: 0,
		value: '00:15:00',
		name: 'Last 15 mins',
	},
	{
		id: 1,
		value: '01:00:00',
		name: 'Last 1 hour',
	},
	{
		id: 2,
		value: '08:00:00',
		name: 'Last 8 hours',
	},
	{
		id: 3,
		value: '1 00:00:00',
		name: 'Last 24 hours',
	},
	{
		id: 4,
		value: '7 00:00:00',
		name: 'Last week',
	},
	{
		id: 5,
		value: '28 00:00:00',
		name: 'Last 4 weeks',
	},
	{
		id: 6,
		value: 'null',
		name: 'Custom',
	},
]
