import {useDispatch} from 'react-redux'
import {showSnackbar} from '../../redux/slices/snackbar'
import {AlertColor} from '@mui/material'

export const useSnackbar = () => {
	const dispatch = useDispatch()
	const showSnackbarFn = (snackbar: { message: string, severity: AlertColor | undefined, action?: string }) => {
		const action = snackbar.action ? snackbar.action : ''
		dispatch(showSnackbar({
			message: snackbar.message,
			severity: snackbar.severity,
			action,
		}))
	}
	return [showSnackbarFn]
}
