import React, {FC} from 'react'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import {
	Box, Divider, LinearProgress, Typography,
} from '@mui/material'
import {useGetLinesWithSearchQuery} from '../../../apis/base.ts'
import LineCard from '../../Dashboards/LinesOverview/components/LineCard.tsx'
import ErrorBoundary from '../../../shared/components/ErrorBoundary.tsx'
import CardViewError from '../../../shared/components/errors/CardView.tsx'
import HorizonatlScrollingContainer from '../../../shared/components/HorizontalScrollingContainer.tsx'

interface SearchErpBatchResultsProps {
    searchTerm: string;
}

const SearchLineResults: FC<SearchErpBatchResultsProps> = (
	{searchTerm},
) => {
	const [t] = useCustomTranslation('common')
	const {
		data: searchLineResults,
		error: searchLineError,
		isFetching: searchLineIsFetching,
	} = useGetLinesWithSearchQuery({search: searchTerm}, {skip: !searchTerm})
	return (
		<Box sx={{p: 1}}>
			{searchLineIsFetching ? (
				<Box>
					<Divider>
						<Typography
							variant="h6"
							color="text.secondary"
							sx={{mb: 1}}
						>
							{t('appbar_search_line.search_running')}
						</Typography>
					</Divider>
					<LinearProgress
						sx={{width: '100%'}}
					/>
				</Box>
			) : searchLineResults && searchLineResults.length === 0 && !searchLineError ? (
				<Divider>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						{t('appbar_search_line.no_results')}
					</Typography>
				</Divider>
			) : searchLineResults && !searchLineError ? (
				<>
					<Divider>
						<Typography
							variant="h6"
							color="text.secondary"
							sx={{mb: 1}}
						>
							{searchLineResults.length}
							{' '}
							{t('appbar_search_line.number_found')}
						</Typography>
					</Divider>
					<HorizonatlScrollingContainer>
						{
							searchLineResults.map((line) => (
								<Box
									key={line.id}
									sx={{
										flex: '0 0 auto',
										width: 300,
										padding: 1,
									}}
								>
									<ErrorBoundary
										fallbackComponent={(
											<CardViewError />
										)}
									>
										<LineCard
											line={line}
											showDetails={false}
										/>
									</ErrorBoundary>
								</Box>
							))
						}
					</HorizonatlScrollingContainer>
				</>
			) : t('messages.unexpected_error')}
		</Box>
	)
}

export default SearchLineResults
