import React, {
	BaseSyntheticEvent, useState,
} from 'react'
import {
	Dialog,
	DialogContent,
	Box,
	FormControlLabel,
	Checkbox,
	Typography,
	Tabs,
	Tab,
} from '@mui/material'
import {useAgreeToTermsMutation} from '../../../apis/user.ts'
import {useSnackbar} from '../../../shared/hooks/useSnackbar'
import {useAuth} from '../../../shared/hooks/useAuth'

const AgreeToTerms = () => {
	const [value, setValue] = useState<string>('terms')
	const [open, setOpen] = useState<boolean>(true)
	const [agreeToTerms] = useState<boolean>(false)
	const [updateUserAgreement, data] = useAgreeToTermsMutation()
	const {updateLocalUser} = useAuth()
	const [showNotifications] = useSnackbar()

	const handleClose = () => {
		setOpen(false)
	}

	const handleUserAgreement = () => {
		updateUserAgreement({
			has_agreed_to_terms_and_conditions: true,
			has_agreed_to_privacy_policy: true,
		})
			.unwrap()
			.then((data) => {
				updateLocalUser(data.user)
				showNotifications({
					message: 'Successfully agreed to Terms & Conditions and Privacy Policy',
					severity: 'success',
				})
				handleClose()
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleChange = (event: BaseSyntheticEvent, newValue: string) => {
		setValue(newValue)
	}

	return (
		<Dialog
			data-testid="mbai-main-agree-to-terms-component"
			fullScreen
			open={open}
			onClose={handleClose}
		>
			<DialogContent>
				<Box
					sx={{
						width: '600px',
						mx: 'auto',
						mt: 5,
					}}
				>
					<Typography
						variant="h4"
						sx={{mb: 2}}
					>
						You must agree to the Terms & Conditions and Privacy Policy before proceeding
					</Typography>
					<Box
						data-testid="mbai-main-settings-component"
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
							mb: 3,
						}}
					>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
							<Tab label="Terms and Conditions" value="terms" id="tab_user_profile" />
							<Tab label="Privacy Policy" value="privacy" id="tab_account_management" />
						</Tabs>
					</Box>
					{
						value === 'terms' ? (
							<iframe
								src="https://montblanc.ai/terms-and-conditions"
								width="600px"
								style={{
		                            border: 'none',
		                            height: '70vh',
		                            borderRadius: '5px',
		                        }}
							/>
						) : value === 'privacy' ? (
							<iframe
								src="https://montblanc.ai/privacy-policy"
								width="600px"
								style={{
		                            border: 'none',
		                            height: '70vh',
		                            borderRadius: '5px',
		                        }}
							/>
						) : null
					}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<FormControlLabel
							control={<Checkbox name="agree_to_terms" />}
							label="I agree to the Terms & Conditions and Privacy Policy"
							checked={agreeToTerms}
							onChange={handleUserAgreement}
							sx={{
								m: 0,
								ml: '-11px',
							}}
						/>
						{
							data.isLoading ? (
								<Typography
									variant="body1"
								>
									Updating...
								</Typography>
							) : null
						}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default AgreeToTerms
