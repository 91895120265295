/* eslint-disable camelcase */
// Importing translations for each language
import * as english from './en'
import * as german from './de'
import * as french from './fr'
import * as spanish from './es'

interface TranslationFile {
	[key: string]: string
}

// Define the type for the language module
interface LanguageModule {
	common: TranslationFile
	lines_overview: TranslationFile
	line_signals: TranslationFile
	batch_traceability: TranslationFile
	login: TranslationFile
	errors: TranslationFile
	tutorial: TranslationFile
	settings: TranslationFile
	line_management: TranslationFile
	dashboards: TranslationFile
	compare_pdfs: TranslationFile
	start_up_kit_onboarding: TranslationFile
	edge_api: TranslationFile
	cockpit: TranslationFile
	anomalies: TranslationFile
}

const translations: (keyof LanguageModule)[] = [
	'lines_overview',
	'line_signals',
	'batch_traceability',
	'common',
	'login',
	'errors',
	'tutorial',
	'settings',
	'line_management',
	'dashboards',
	'compare_pdfs',
	'start_up_kit_onboarding',
	'edge_api',
	'cockpit',
	'anomalies',
]

const getTranslationObject = (language: unknown) => translations.reduce<LanguageModule>((acc, key) => {
	acc[key] = (language as LanguageModule)[key]
	return acc
}, {} as LanguageModule)

export default {
	en: getTranslationObject(english),
	de: getTranslationObject(german),
	fr: getTranslationObject(french),
	es: getTranslationObject(spanish),
}
