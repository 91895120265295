import {baseApi} from './base'
import {FeatureCollection} from 'geojson'
import {BaseFetchResultsInterface} from '../shared/interfaces/base.ts'
import {CockpitWarningInterface} from '../shared/interfaces/cockpitWarning.ts'

const cockpitAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getCockpitList: builder.query<FeatureCollection, void>({
			query: () => 'cockpit/',
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getCockpitWarningsSignalBased: builder.query<CockpitWarningInterface[], {
            equipmentId?: string, lineId?: string
        }>({
        	query: ({
        		equipmentId, lineId,
        	}) => ({
        		url: 'cockpitwarningssignalbased/',
        		params: equipmentId ? {signal__machine_sub_system: equipmentId} : {signal__machine_sub_system__machine: lineId},
        	}),
        	transformResponse: (response: BaseFetchResultsInterface<CockpitWarningInterface>) => response.results,
        	extraOptions: {refetchOnMountOrArgChange: true},
        }),
	}),
	overrideExisting: false,
})

export const {
	useGetCockpitListQuery, useGetCockpitWarningsSignalBasedQuery,
} = cockpitAPI
