import {baseApi} from './base'
import {BaseFetchResultsInterface} from '../shared/interfaces/base'
import {MetricOptionsInterface} from '../shared/interfaces/metricOptions.ts'
import {WeeklySummaryDataInterface} from '../shared/interfaces/weeklySummaryBar.ts'
import {AvailabilityPieInterface} from '../shared/interfaces/availabilityPie.ts'
import {CalendarMetricInterface} from '../shared/interfaces/calendarMetric.ts'
import {ProductionTimelineByEquipmentInterface} from '../shared/interfaces/productionTimeline.ts'

interface AvailabilityData {
	date: string;
	classification: string;
	duration: number;
	id: string;
  }

const lineDashboardAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getMetricOptions: builder.query({
			query: ({
				lineId,
				equipmentId,
			}) => `metrics/?machine_id=${lineId}&machine_sub_system_id=${equipmentId}`,
			extraOptions: {refetchOnMountOrArgChange: true},
			transformResponse: (response: BaseFetchResultsInterface<MetricOptionsInterface>) => response.results,
		}),
		getMetricOverTime: builder.query<WeeklySummaryDataInterface[], {
            metricId: string,
            summarizeBy: string,
            showBy: string,
            startDate: string,
            endDate: string
        }>({
        	query: ({
        		metricId,
        		summarizeBy,
        		showBy,
        		startDate,
        		endDate,
        	}) => `metrics/${metricId}/over_time/?summarize_by=${summarizeBy}&show_by=${showBy}&t_start__gte=${startDate}&t_end__lte=${endDate}`,
        	extraOptions: {refetchOnMountOrArgChange: true},
        	transformResponse: (response: BaseFetchResultsInterface<WeeklySummaryDataInterface>) => response.results,
        }),
		getAvailabilityPie: builder.query<AvailabilityPieInterface[], { lineId: string, startDate: string, endDate: string }>({
			query: ({
				lineId,
				startDate,
				endDate,
			}) => `machinesubsystems/availability_pies/?machine=${lineId}&t_start__gte=${startDate}&t_end__lte=${endDate}`,
			transformResponse: (response: BaseFetchResultsInterface<AvailabilityPieInterface>) => response.results,
		}),
		getAvailabilityPiePerEquipment: builder.query<AvailabilityPieInterface, {equipmentId: string, startDate?: string, endDate?: string }>({
			query: ({
				equipmentId, startDate, endDate,
			}) => `machinesubsystems/${equipmentId}/availability_pie/?t_start__gte=${startDate}&t_end__lte=${endDate}`,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getAvailabilityPerDay: builder.query<AvailabilityData[], { equipmentId: string, startDate: string, endDate: string, timeZoneOffset: number, summarize_by:string }>({
			query: ({
				equipmentId,
				startDate,
				endDate,
				timeZoneOffset,
				summarize_by,
			}) => `machinesubsystems/${equipmentId}/daily_availabilities/?t_start__gte=${startDate}&t_end__lte=${endDate}&timeZoneOffset=${timeZoneOffset}&summarize_by=${summarize_by}`,
		}),
		getCalendarMetricPerDay: builder.query<CalendarMetricInterface[], { lineId: string, equipmentId: string, metric: string }>({
			query: ({
				lineId,
				equipmentId,
				metric,
			}) => `machinesubsystems/${equipmentId}/metric_per_day/?machine=${lineId}&metric=${metric}`,
			transformResponse: (response: BaseFetchResultsInterface<CalendarMetricInterface>) => response.results,
		}),
		getEquipmentsTimeline: builder.query<ProductionTimelineByEquipmentInterface, { lineId: string, startDate: string, endDate: string }>({
			query: ({
				lineId,
				startDate,
				endDate,
			}) => `batches/timeline_by_mss/?machine=${lineId}&start_date=${startDate}&end_date=${endDate}`,
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetMetricOverTimeQuery,
	useGetMetricOptionsQuery,
	useGetAvailabilityPieQuery,
	useGetAvailabilityPiePerEquipmentQuery,
	useGetCalendarMetricPerDayQuery,
	useGetAvailabilityPerDayQuery,
	useGetEquipmentsTimelineQuery,
} = lineDashboardAPI
