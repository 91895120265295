// AuthProvider.tsx
import React, {
	createContext,
	useContext,
	FC,
	useState,
	ReactElement,
	useMemo, ReactNode,
} from 'react'
import {AuthResponseInterface} from '../../shared/interfaces/auth'

interface UserProviderProps {
    children?: ReactNode
}

export interface AuthContextProps {
    userIsLoggedIn: boolean
    loginUser: (creds: AuthResponseInterface | string) => void
    logoutUser: () => void
}

const AuthContext = createContext<AuthContextProps | null>(null)

const useAuthContext = (): AuthContextProps | null => useContext(AuthContext)

const AuthProvider: FC<UserProviderProps> = ({children}): ReactElement => {
	const auth = localStorage.getItem('token') ? localStorage.getItem('token') : null
	const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(!!auth)

	const loginUser = (creds: AuthResponseInterface | string) => {
		const token = typeof creds === 'string' ? creds : creds.token
		localStorage.setItem('token', token)
		setUserIsLoggedIn(true)
	}

	const logoutUser = () => {
		// Perform logout logic here
		localStorage.removeItem('token')
		setUserIsLoggedIn(false)
	}

	return (
		<AuthContext.Provider value={useMemo(() => ({
			userIsLoggedIn, loginUser, logoutUser,
		}), [userIsLoggedIn, loginUser, logoutUser])}
		>
			{children}
		</AuthContext.Provider>
	)
}

export {
	useAuthContext, AuthProvider,
}
