import React, {
	StrictMode, useState, useCallback, FC, useEffect,
} from 'react'
import {
	Chip,
	CircularProgress,
	Divider,
	Grid,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material'
import {NotificationInterface} from '../../../shared/interfaces/notification'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import {useUpdateNotificationReadMutation} from '../../../apis/base.ts'
import {useSnackbar} from '../../../shared/hooks/useSnackbar'
import EmailIcon from '@mui/icons-material/Email'
import DraftsIcon from '@mui/icons-material/Drafts'
import dayjs from 'dayjs'

interface Props {
    notification: NotificationInterface
    onReadUpdate: (newReadStatus: boolean) => void;
}

const NotificationListItem: FC<Props> = ({
	notification, onReadUpdate,
}): JSX.Element => {
	const [showNotification] = useSnackbar()
	const [updateNotificationRead, {isLoading}] = useUpdateNotificationReadMutation()
	const [t] = useCustomTranslation('common')
	const [localNotification, setLocalNotification] = useState(notification)

	useEffect(() => {
		setLocalNotification(notification)
	}, [notification])

	const updateNotification = (notificationId: string, newReadStatus: boolean) => {
		updateNotificationRead({
			notificationId,
			readByCurrentUser: newReadStatus,
		}).unwrap()
			.then(() => {
				showNotification({
					message: t('messages.successful_update'),
					severity: 'success',
				})
				const newNotification = {...localNotification}
				newNotification.read_by_current_user = newReadStatus
				setLocalNotification(newNotification)
				onReadUpdate(newReadStatus)
			})
			.catch((error) => {
				showNotification({
					message: t('messages.unexpected_error'),
					severity: 'error',
				})
				console.error(error)
			})
	}

	const getChipLabel = useCallback(() => {
		switch (localNotification.severity) {
			case 'warning':
				return t('appbar.notifications.warning')
			case 'critical':
				return t('appbar.notifications.critical')
			default:
				return 'Unexpected'
		}
	}, [localNotification])

	const getChipColor = useCallback(() => {
		switch (localNotification.severity) {
			case 'warning':
				return 'warning'
			case 'critical':
				return 'error'
			default:
				return 'error'
		}
	}, [localNotification])

	return (
		<StrictMode key={localNotification.id}>
			<ListItemButton
				alignItems="flex-start"
				disabled={isLoading}
				data-testid="mbai-main-notification-list-item"
			>
				<ListItemIcon
					aria-label="comment"
					sx={{
						minWidth: 45,
						paddingTop: 1,
					}}
				>
					{isLoading ? (
						<CircularProgress
							size={15}
						/>
					) : localNotification.read_by_current_user ? (
						<Tooltip
							title={
								localNotification.read_by_current_user ? t('appbar.notifications.mark_as_unread') : t('appbar.notifications.mark_as_read')
							}
						>
							<DraftsIcon
								color="success"
								onClick={() => updateNotification(localNotification.id, !localNotification.read_by_current_user)}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title={
								localNotification.read_by_current_user ? t('appbar.notifications.mark_as_unread') : t('appbar.notifications.mark_as_read')
							}
						>
							<EmailIcon
								color="primary"
								onClick={() => updateNotification(localNotification.id, !localNotification.read_by_current_user)}
							/>
						</Tooltip>
					)}
				</ListItemIcon>
				<ListItemText
					primary={(
						<Typography
							color={localNotification.read_by_current_user ? 'text.secondary' : 'primary'}
							fontWeight="bold"
						>
							{localNotification.name}
						</Typography>
					)}
					secondary={(
						<>
							<Typography>
								{localNotification.description}
							</Typography>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								paddingTop={1}

							>
								<Grid item xs={6}>
									<Typography
										color="text.disabled"
									>
										{dayjs(new Date(localNotification.created_on)).format('MMM D, YYYY | H:mm')}
									</Typography>
								</Grid>
								<Grid item xs={6} display="flex" justifyContent="flex-end">
									{
										['warning', 'critical'].includes(localNotification.severity) ? (
											<Chip
												label={getChipLabel()}
												color={getChipColor()}
												size="small"
											/>
										) : null
									}
								</Grid>
							</Grid>
						</>
					)}
				/>
			</ListItemButton>
			<Divider />
		</StrictMode>
	)
}

export default NotificationListItem
