import React, {
	ReactElement, useCallback, FC, useEffect,
} from 'react'
import {
	Box, Tooltip, IconButton,
} from '@mui/material'
import {
	useDispatch, useSelector,
} from 'react-redux'
import {setPreferredTheme} from '../../../redux/slices/user'
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone'
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import {RootState} from '../../App/store'

type Props = {
	prefered_theme?: string
}

const ToggleTheme: FC<Props> = ({prefered_theme}): ReactElement => {
	const dispatch = useDispatch()
	const switchToDark = useCallback(
		() => dispatch(setPreferredTheme('dark')),
		[dispatch],
	)

	const switchToLight = useCallback(
		() => dispatch(setPreferredTheme('light')),
		[dispatch],
	)

	const theme = useSelector((state: RootState) => state.user.preferredTheme)

	useEffect(() => {
		if (prefered_theme) dispatch(setPreferredTheme(prefered_theme))
	}, [prefered_theme])

	const [t] = useCustomTranslation('common')

	return (
		<Box
			id="mbai-appbar-theme-toggle"
			data-testid="mbai-main-toggle-theme-component"
			sx={{
				display: 'flex',
				alignSelf: 'center',
				ml: 1,
			}}
			className="selectorTheme"
		>
			{
				theme === 'light' ? (
					<Tooltip
						title={t('appbar.switch_to_dark')}
						arrow
					>
						<IconButton
							onClick={() => switchToDark()}
						>
							<DarkModeTwoToneIcon
								id="appbar-theme-icon"
								data-value="light"
								sx={{
									color: 'grey.700',
									cursor: 'pointer',
								}}
							/>
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip
						title={t('appbar.switch_to_light')}
						arrow
					>
						<IconButton
							onClick={() => switchToLight()}
						>
							<LightModeTwoToneIcon
								id="appbar-theme-icon"
								data-value="dark"
								sx={{cursor: 'pointer'}}
							/>
						</IconButton>
					</Tooltip>
				)
			}
		</Box>
	)
}

export default ToggleTheme
