import {Grid} from '@mui/material/'
import {
	StrictMode, useEffect, FC,
	ChangeEvent,
} from 'react'
import {
	TextValidator, ValidatorForm,
} from 'react-material-ui-form-validator'
import {useLazyGetConnectionsByIdentifierQuery} from '../../../../../../apis/edgeAPI.ts'
import {useSearchParams} from 'react-router-dom'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation.ts'
import {
	useDispatch, useSelector,
} from 'react-redux'
import {RootState} from '../../../../../App/store.ts'
import {
	setValidate, setActiveStep,
} from '../../../redux/slices/edgeApiNavigationFormSlice.ts'
import {
	setIdentifier, setData,
} from '../../../redux/slices/edgeApiConnectionFormSlice.ts'

const AddConnectionValidatorForm:FC = () => {
	const [t] = useCustomTranslation('edge_api')
	const [searchParams, setSearchParams] = useSearchParams()
	const dispatch = useDispatch()
	const {identifier} = useSelector((state: RootState) => state.edgeApiConnectionForm)
	const {validate} = useSelector((state: RootState) => state.edgeApiNavigationForm)
	const [trigger, {
		data, error,
	}] = useLazyGetConnectionsByIdentifierQuery()

	const handleSubmitIdentifier = () => {
		trigger({identifier})
		dispatch(setValidate(false))
	}

	useEffect(() => {
		if (validate) {
			handleSubmitIdentifier()
		}
	}, [validate])

    	useEffect(() => {
		if (data) {
			dispatch(setData(data))
		}

		if (data && !data.tenant_id) {
			const newSearchParams = new URLSearchParams(searchParams)
			newSearchParams.set('identifier', identifier)
			newSearchParams.set('id', data.id)
			setSearchParams(newSearchParams)
			dispatch(setActiveStep(2))
		}
	}, [data, error])

	const handleKeyPress = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault() // Prevent form submission
			handleSubmitIdentifier() // You can still manually trigger the submit logic if needed
		}
	}

	return (
		<StrictMode>
			<ValidatorForm onSubmit={handleSubmitIdentifier} onKeyPress={handleKeyPress}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextValidator
							fullWidth
							label={t('identifier')}
							sx={{marginY: 1}}
							variant="outlined"
							name="identifier"
							id="identifier"
							validators={['required']}
							errorMessages={['Identifier field is required']}
							inputProps={{maxLength: 100}}
							value={identifier}
							onChange={(event: ChangeEvent<HTMLInputElement>) => dispatch(setIdentifier(event.target.value))}
							error={!!error || !!data?.tenant_id}
							helperText={error ? 'Identifier not found' : data?.tenant_id ? 'Identifier already registered' : ''}
							data-cy="mbai-add-connection-form-identifier"
						/>
					</Grid>
				</Grid>
			</ValidatorForm>
		</StrictMode>
	)
}

export default AddConnectionValidatorForm
