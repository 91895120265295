import React, {
	FC, ReactElement, ReactNode,
} from 'react'
import {NavLink} from 'react-router-dom'
import {
	ListItem, Tooltip, IconButton, Box,
} from '@mui/material'
import OverflowTypography from '../../../shared/components/OverflowTypography.tsx'

interface DrawerListItemProps {
    text: {
        link: string
        icon: ReactNode
        title: string | Element
    }
    index?: number
    open: boolean
    idProp: string
}

const DrawerListItem: FC<DrawerListItemProps> = (
	{
		text,
		index,
		open,
		idProp,
	},
): ReactElement => {
	const location = window.location.pathname
	return (
		<NavLink to={text.link as string} key={index} id="line-overview-main-link">
			{
				open ? (
					<ListItem
						data-testid="mbai-main-drawer-list-item"
						disablePadding
						sx={{
							display: 'block',
							pl: 1.4,
							py: 0.5,
							'& .MuiBox-root': {
								mr: 1.4,
								borderRadius: '25px',
								'&:hover': {backgroundColor: 'divider'},
							},
							'& .MuiBox-root.active': {
								backgroundColor: 'primary.main',
								color: 'primary.contrastText',
								'& .MuiSvgIcon-root': {color: 'primary.contrastText'},
								'& .MuiTypography-root': {color: 'primary.contrastText'},
							},
						}}
						id={idProp}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
							className={location.includes((text.link as string).split('?')[0]) || (location === '/' && (text.link as string).includes('lines_overview')) ? 'active' : ''}
						>
							<IconButton
								size="large"
							>
								{text.icon}
							</IconButton>
							<OverflowTypography
								variant="body2"
								sx={{
									display: open ? 'block' : 'none',
									ml: 0.5,
									color: 'text.secondary',
								}}
							>
								{text.title as string}
							</OverflowTypography>
						</Box>
					</ListItem>
				) : (
					<Tooltip
						title={text.title as string}
						placement="right"
					>
						<ListItem
							data-testid="mbai-main-drawer-list-item"
							disablePadding
							sx={{
								display: 'block',
								pl: 1.4,
								py: 0.5,
								'& .MuiBox-root': {
									mr: 1.4,
									borderRadius: '25px',
									'&:hover': {backgroundColor: 'divider'},
								},
								'& .MuiBox-root.active': {
									backgroundColor: 'primary.main',
									color: 'primary.contrastText',
									'& .MuiSvgIcon-root': {color: 'primary.contrastText'},
									'& .MuiTypography-root': {color: 'primary.contrastText'},
								},
							}}
							id={idProp}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
								className={location.includes((text.link as string).split('?')[0]) || (location === '/' && (text.link as string).includes('lines_overview')) ? 'active' : ''}
							>
								<IconButton
									size="large"
								>
									{text.icon}
								</IconButton>
								<OverflowTypography
									variant="body2"
									sx={{
										display: open ? 'block' : 'none',
										ml: 0.5,
										color: 'text.secondary',
									}}
								>
									{text.title as string}
								</OverflowTypography>
							</Box>
						</ListItem>
					</Tooltip>
				)
			}
		</NavLink>
	)
}

export default DrawerListItem
