import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'

export const components = createSlice({
	name: 'components',
	initialState: {leftSidebar: {open: true}},
	reducers: {
		setLeftSidebarOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			leftSidebar: {
				...state.leftSidebar,
				open: action.payload,
			},
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setLeftSidebarOpen} = components.actions
export default components.reducer
