import React, {
	FC, ReactElement, ReactNode, StrictMode, useCallback,
} from 'react'
import {
	Box, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography,
} from '@mui/material'
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined'
import {NavLink} from 'react-router-dom'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import LineMenu from './LineMenu'
import EditIcon from '@mui/icons-material/Edit'
import BarChartIcon from '@mui/icons-material/BarChart'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {LineInterface} from '../../../../shared/interfaces/line'
import BasicCard from '../../../../shared/components/BasicCard.tsx'
import ActionButton from './ActionButton.tsx'
import {useGetCockpitWarningsSignalBasedQuery} from '../../../../apis/cockpit.ts'
import CardWarning from '../shared/CardWarning.tsx'
import {filterWarnings} from '../../../../util-functions.ts'

const LineCard: FC<{ line: LineInterface, showDetails?: boolean }> = ({
	line, showDetails = true,
}): ReactElement => {
	const getClassName = useCallback((image: string): string => (image ? 'valid-line-image' : 'no-valid-image'), [])
	const {data: cockpitWarnings} = useGetCockpitWarningsSignalBasedQuery(
		{lineId: line.id as string},
	)

	const getImageIcon = useCallback((image: string): ReactNode | null => (!image ? (
		<Box
			display="flex"
			height="100%"
			justifyContent="center"
			alignItems="center"
		>
			<BrokenImageOutlinedIcon
				sx={{
					height: '75px',
					width: '100px',
					color: 'divider',
				}}
				className="no-image-card"

			/>
		</Box>
	) : null), [])

	const [t] = useCustomTranslation('lines_overview')

	const filteredWarnings = filterWarnings(cockpitWarnings || [])

	return (
		<StrictMode>
			<BasicCard
				key={line.id}
				id={line.id}
				data-name="line-card"
				data-testid="mbai-lines-overview-line-card-component"
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<CardHeader
					title={(
						<NavLink to={`/line/${line.id}`} style={{textDecoration: 'none'}}>
							<Typography
								variant="h3"
								data-name="line-title"
								data-cy="mbai-lines-overview-line-card-title"
								sx={{
									color: 'text.primary',
									mb: 0,
								}}
							>
								{line.name}
							</Typography>
						</NavLink>
					)}
					action={(
						<Box
							sx={{display: 'flex'}}
						>
							{filteredWarnings && filteredWarnings.length > 0 ? (
								<CardWarning
									warnings={filteredWarnings}
									lineId={line.id}
								/>
							) : null}
							<LineMenu
								lineId={line.id}
							/>
						</Box>
					)}
					sx={{
						pb: 0,
						borderBottom: 'none !important',
					}}
				/>
				<NavLink to={`/line/${line.id}`}>
					<CardMedia
						className={line && line.image ? getClassName(line.image) : ''}
						component="div"
						image={line.image}
						sx={{
							height: '200px',
							mx: 2,
							mt: 2,
							backgroundColor: 'background.fadedOpaque',
						}}
						data-cy="mbai-line-card-image"
					>
						{getImageIcon(line.image)}
					</CardMedia>
				</NavLink>
				{
					showDetails && (
						<CardContent
							sx={{'&:last-child': {pb: 3}}}
						>
							{
								line.description ? (
									<Typography
										variant="body2"
										color="text.secondary"
										sx={{mb: 2}}
									>
										{line.description}
									</Typography>
								) : null
							}
							{line.building && (
								<Box
									sx={{mb: '-3px'}}
								>
									<Typography
										variant="caption"
										color="text.disabled"
										component="span"
									>
										{t('building')}
										:
									</Typography>
									<Typography
										variant="caption"
										color="text.primary"
										component="span"
										sx={{ml: 0.5}}
									>
										{line.building.name}
									</Typography>
								</Box>
							)}
							{line.machine_type && (
								<Box
									sx={{mb: '-3px'}}
								>
									<Typography
										variant="caption"
										color="text.disabled"
										component="span"
									>
										{t('line_type')}
										:
									</Typography>
									<Typography
										variant="caption"
										color="text.primary"
										component="span"
										sx={{ml: 0.5}}
									>
										{line.machine_type.name}
									</Typography>
								</Box>
							)}
						</CardContent>
					)
				}

				<CardActions
					sx={{
						px: 2,
						pb: 2,
						pt: '6px !important',
						mt: 'auto',
						borderTop: 'none !important',
					}}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={t('view_line')}
								dataName="line-view-line-button"
								dataCy="mbai-lines-overview-line-card-view-line-button"
								link={`/line/${line.id}`}
								icon={<BarChartIcon />}
							/>
						</Grid>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={t('view_signals')}
								dataName="line-view-signals-button"
								dataCy="mbai-lines-overview-line-card-view-signals-button"
								link={`/line/${line.id}/signals`}
								icon={<TrendingUpIcon />}
							/>
						</Grid>
						<Grid
							item
							xs={4}
						>
							<ActionButton
								title={t('edit_line')}
								dataCy="mbai-lines-overview-line-card-view-line-management-button"
								link={`/line/${line.id}/line_management`}
								icon={<EditIcon />}
							/>
						</Grid>
					</Grid>
				</CardActions>
			</BasicCard>
		</StrictMode>
	)
}

export default LineCard
