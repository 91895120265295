import {CardContent} from '@material-ui/core'
import {
	Button, Card, CardActions, CardMedia, Typography, CardHeader,
} from '@mui/material/'
import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {useCustomTranslation} from '../../../../../../shared/hooks/useCustomTranslation'
import UseStepNavigation from '../../../utils/hooks/UseStepNavigation'

interface ConnectionOptionCardProps {
    image?: string;
    text: string;
    alt?: string;
    value: string;
}

const ConnectionOptionCard: React.FC<ConnectionOptionCardProps> = ({
	image, text, alt, value,
}) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const {handleNext} = UseStepNavigation()
	const [t] = useCustomTranslation('edge_api')

	const handleClick = () => {
		const newSearchParams = new URLSearchParams(searchParams)
		newSearchParams.set('device_type', value)
		setSearchParams(newSearchParams)
		if (typeof handleNext === 'function') {
			handleNext()
		}
	}

	return (
		<Card>
			{value === 'factor_201' ? (
				<>
					<CardHeader
						title={text}
					/>
					<CardMedia
						component="img"
						height="130"
						image={image}
						alt={alt}
						style={{
							width: '100%',
							objectFit: 'contain',
						}}
					/>
					<CardActions sx={{justifyContent: 'center'}}>
						<Button
							variant="contained"
							data-testid="mbai-edge-api-buy-connection-device"
							onClick={handleClick}
							className="selectorConfigureMachinePageAddProgram"
						>
							{t('buttons.select')}
						</Button>
						<Button
							variant="contained"
							data-testid="mbai-edge-api-buy-connection-device"
							onClick={() => window.open('https://montblanc.ai/snap-edge-solution/', '_blank')}
							className="selectorConfigureMachinePageAddProgram"
						>
							{t('buttons.learn_more')}
						</Button>
					</CardActions>
				</>
			) : value === 'own_mqtt_publisher' ? (
				<>
					<CardContent
						style={{
							height: '198px',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography variant="h5" component="div" sx={{textAlign: 'center'}}>
							{text}
						</Typography>
					</CardContent>
					<CardActions sx={{justifyContent: 'center'}}>
						<Button
							variant="contained"
							data-testid="mbai-edge-api-buy-connection-device"
							onClick={handleClick}
							className="selectorConfigureMachinePageAddProgram"
						>
							{t('buttons.select')}
						</Button>
						<Button
							variant="contained"
							data-testid="mbai-edge-api-buy-connection-device"
							onClick={() => window.open('https://montblanc.ai/snap-edge-solution/', '_blank')}
							className="selectorConfigureLinePageAddProgram"
						>
							{t('buttons.learn_more')}
						</Button>
					</CardActions>
				</>
			) : (
				<>
					<CardContent
						style={{
							height: '198px',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography variant="h5" component="div" sx={{textAlign: 'center'}}>
							{text}
						</Typography>
					</CardContent>
					<CardActions sx={{justifyContent: 'center'}}>
						<Button
							variant="contained"
							data-testid="mbai-edge-api-buy-connection-device"
							onClick={() => window.open('https://meetings.hubspot.com/luc-atangana/', '_blank')}
							className="selectorConfigureLinePageAddProgram"
						>
							{t('buttons.learn_more')}
						</Button>
					</CardActions>
				</>
			)}
		</Card>
	)
}

export default ConnectionOptionCard
