import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {ViewInterface} from '../../shared/interfaces/view'

type InitialStateInterface = {
	all: {
		isLoading: boolean,
		isFetching: boolean,
		isError: boolean,
		data: ViewInterface[]
	},
	activeView: ViewInterface | Object
}

const initialState: InitialStateInterface = {
	all: {
		isLoading: true,
		isFetching: true,
		isError: false,
		data: [],
	},
	activeView: {},
}

export const views = createSlice({
	name: 'views',
	initialState,
	reducers: {
		setAllViews: (state, action) => ({
			...state,
			all: {
				...state.all,
				isError: action.payload.isError,
				isLoading: action.payload.isLoading,
				isFetching: action.payload.isFetching,
				data: action.payload.data,
			},
		}),
		setActiveView: (state, action: PayloadAction<ViewInterface>) => ({
			...state,
			activeView: action.payload,
		}),
		setActiveViewName: (state, action: PayloadAction<string>) => ({
			...state,
			activeView: {
				...state.activeView,
				name: action.payload,
			},
		}),
		setActiveViewDescription: (state, action: PayloadAction<string>) => ({
			...state,
			activeView: {
				...state.activeView,
				description: action.payload,
			},
		}),
	},
})

// Action creators are generated for each case reducer function
export const {
	setAllViews, setActiveView, setActiveViewName, setActiveViewDescription,
} = views.actions
export default views.reducer
