import {
	StrictMode, FC, ReactElement, useCallback,
} from 'react'
import MBAIMainDrawer from './drawer/Drawer'
import {
	Box,
	CssBaseline,
} from '@mui/material'

interface MBAIMainSideMenuProps {
	open: boolean
	onMenuOpen: () => void
}

const MBAIMainSideMenu: FC<MBAIMainSideMenuProps> = ({
	onMenuOpen, open,
}): ReactElement => {
	const toggleDrawer = useCallback(() => {
		onMenuOpen()
	}, [open])

	return (
		<StrictMode>
			<Box
				sx={{display: 'flex'}}
				data-testid="mbai-main-layout-component"
			>
				<CssBaseline />
				<MBAIMainDrawer
					open={open}
					onDrawerOpen={toggleDrawer}
				/>
			</Box>
		</StrictMode>
	)
}

export default MBAIMainSideMenu
