import React, {
	FC, ReactElement,
} from 'react'
import {
	InputLabel, styled,
} from '@mui/material'

interface BasicLabelProps {
	label: string
}

const MBAIBaseLabel = styled(InputLabel)(({theme}) => ({
	color: theme.palette.text.primary,
	fontSize: '1.1rem',
	fontFamily: 'Circular Bold',
}))

const BasicLabel: FC<BasicLabelProps> = (
	{label},
): ReactElement => (
	<MBAIBaseLabel
		data-testid="mbai-main-basic-label"
		shrink
		htmlFor="bootstrap-input"
	>
		{label}
	</MBAIBaseLabel>
)

export default BasicLabel
