import {useLineOverviewSteps} from './useTutorialSteps/useLineOverviewSteps'
import {StepsInterface} from '../interfaces/steps'

export const useTutorial = (): StepsInterface[] => {
	const machineOverviewSteps = useLineOverviewSteps()
	const steps = [
		...machineOverviewSteps,
	]
	return steps
}
