import {
	useRef, useState, useEffect, ReactElement, FC,
} from 'react'
import {
	Typography, Tooltip, TypographyProps,
} from '@mui/material'

interface OverflowTypographyProps extends TypographyProps {
    children: string
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
}
const OverflowTypography: FC<OverflowTypographyProps> = ({
	children, tooltipPlacement = 'bottom', ...props
}): ReactElement => {
	const textRef = useRef<HTMLElement>(null)
	const [isOverflow, setIsOverflow] = useState(false)

	const checkOverflow = () => {
		const {current} = textRef
		if (!current) return
		setIsOverflow(current.scrollWidth > current.clientWidth)
	}

	useEffect(() => {
		checkOverflow()
		window.addEventListener('resize', checkOverflow)
		return () => {
			window.removeEventListener('resize', checkOverflow)
		}
	}, [children])

	return (
		<Tooltip
			title={isOverflow ? children : ''}
			placement={tooltipPlacement}
		>
			<Typography
				ref={textRef}
				data-testid="mbai-overflow-typography"
				{...props}
			>
				{children}
			</Typography>
		</Tooltip>
	)
}

export default OverflowTypography
