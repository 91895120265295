import {FC} from 'react'
import {CockpitWarningInterface} from '../../../../shared/interfaces/cockpitWarning'
import WarningIcon from '@mui/icons-material/Warning'
import {Tooltip} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

type Props = {
    warnings: CockpitWarningInterface[]
    lineId?: string
    equipmentId?: string
}

const CardWarning: FC<Props> = ({
	warnings, lineId, equipmentId,
}) => {
	const [t] = useCustomTranslation('lines_overview')

	const generateLink = () => {
		if (lineId) {
			return `/?tab=cockpit&element_id=${lineId}&element_type=machine&panelOpen=true`
		} if (equipmentId) {
			return `/?tab=cockpit&element_id=${equipmentId}&element_type=machinesubsystem&panelOpen=true`
		}
		return '/'
	}

	const tooltipTitle = () => (warnings.length > 1 ? `${warnings.length} ${t('warnings')}` : `${warnings.length} ${t('warning')}`)

	return (
		<NavLink to={generateLink()} style={{textDecoration: 'none'}} data-testid="navlink-card-warning">
			<Tooltip title={tooltipTitle()}>
				<WarningIcon sx={{color: 'primary.main'}} />
			</Tooltip>
		</NavLink>
	)
}

export default CardWarning
