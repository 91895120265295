import React, {
	FC, ReactElement,
} from 'react'
import {
	Box, Skeleton, Toolbar,
} from '@mui/material'
import GlobalIconLoader from '../GlobalIconLoader'
import BasicTextLoader from '../BasicTextLoader'

const MinimizedTemplateLoader: FC = (): ReactElement => (
	<Box
		data-testid="mbai-minimized-template-loader"
		sx={{
			width: '100%',
			height: '100vh',
		}}
	>
		<Toolbar
			sx={{
				borderBottom: 1,
				borderBottomColor: 'divider',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					flexGrow: 1,
				}}
			>
				<Skeleton
					variant="rounded"
					sx={{
						width: '175px',
						height: '30px',
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{
						[...Array(2)].map((item) => (
							<GlobalIconLoader
								item={item}
							/>
						))
					}
				</Box>
			</Box>
		</Toolbar>
		<Box
			sx={{
				py: 2,
				pl: 3,
				pr: 2,
				height: 'calc(100vh - 69px)',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<BasicTextLoader width={400} />
			<Box
				sx={{
					width: '100%',
					height: '100%',
					mt: 2,
				}}
			>
				<Skeleton
					variant="rounded"
					sx={{height: '100%'}}
				/>
			</Box>
		</Box>
		<Box
			sx={{
				position: 'fixed',
				bottom: '16px',
				right: '16px',
				width: '200px',
				height: '48px',
			}}
		>
			<BasicTextLoader />
		</Box>
	</Box>
)

export default MinimizedTemplateLoader
