import React, {FC} from 'react'
import {
	CardActions, CardContent, CardHeader, Divider, Grid, LinearProgress, ListItem, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import {
	formatRollupValue, getFormattedDate,
} from '../../../util-functions.ts'
import {
	UserContextProps, useUserContext,
} from '../../App/UserProvider.tsx'
import {EquipmentTimeWindowInterface} from '../../../shared/interfaces/equipmentTimeWindowInterface.ts'
import BasicCard from '../../../shared/components/BasicCard.tsx'
import ActionButton from '../../Dashboards/LinesOverview/components/ActionButton.tsx'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import {useGetSignalRollupsWithValueQuery} from '../../../apis/base.ts'
import OverflowTypography from '../../../shared/components/OverflowTypography.tsx'
import BarChartIcon from '@mui/icons-material/BarChart'
import ScienceIcon from '@mui/icons-material/Science'

interface EquipmentTimeWindowCardProps {
    equipmentTimeWindow: EquipmentTimeWindowInterface;
}

const EquipmentTimeWindowCard: FC<EquipmentTimeWindowCardProps> = ({equipmentTimeWindow}) => {
	const [t] = useCustomTranslation('common')
	const [tLineManagement] = useCustomTranslation('line_management')
	const {currentUser} = useUserContext() as UserContextProps
	const limsLink = equipmentTimeWindow.erp_batch_identifier && currentUser?.userprofile?.lims_url_template?.replace(
		'{erp_batch_identifier}',
		equipmentTimeWindow.erp_batch_identifier,
	)

	const {
		data: signalRollups, isFetching: signalRollupsIsFetching, error: signalRollupsError,
	} = useGetSignalRollupsWithValueQuery({
		equipmentId: equipmentTimeWindow.machine_sub_system.id,
		tStart: equipmentTimeWindow.t_start,
		tEnd: equipmentTimeWindow.t_end,
	})

	const addDays = (date: Date, days: number) => {
		const result = new Date(date)
		result.setDate(result.getDate() + days)
		return result
	}

	const tStartWithMargin = addDays(new Date(equipmentTimeWindow.t_start), -3)
	const tEndWithMargin = addDays(new Date(equipmentTimeWindow.t_end), 3)

	return (
		<BasicCard>
			<CardHeader title={equipmentTimeWindow.machine_sub_system.name} />
			<CardContent>
				<Typography
					component="span"
					variant="body2"
					sx={{
						color: 'text.disabled',
						mr: 0.5,
					}}
				>
					{t('program')}
					:
				</Typography>
				<strong>
					{equipmentTimeWindow.programs.map((program) => program.name).join(', ')}
				</strong>
				<br />

				<Typography
					component="span"
					variant="body2"
					sx={{
						color: 'text.disabled',
						mr: 0.5,
					}}
				>
					{t('t_start')}
					:
				</Typography>
				<strong>
					{getFormattedDate(equipmentTimeWindow.t_start, currentUser, {
						showDate: true,
						showTime: true,
						showSeconds: true,
						showYear: true,
					})}
				</strong>

				<br />

				<Typography
					component="span"
					variant="body2"
					sx={{
						color: 'text.disabled',
						mr: 0.5,
					}}
				>
					{t('t_end')}
					:
				</Typography>
				<strong>
					{getFormattedDate(equipmentTimeWindow.t_end, currentUser, {
						showDate: true,
						showTime: true,
						showSeconds: true,
						showYear: true,
					})}
				</strong>
			</CardContent>
			<Divider sx={{borderWidth: '1px'}} />
			<CardContent>

				{signalRollupsIsFetching ? (
					<LinearProgress
						sx={{width: '100%'}}
					/>
				) : signalRollups && signalRollups.length === 0 && !signalRollupsError ? (
					<Typography>
						{t('search_by_erp_batch_not_found')}
					</Typography>
				) : signalRollups ? (
					<>
						<Typography
							variant="caption"
							component="span"
							sx={{
								color: 'text.disabled',
								mr: 0.5,
							}}
						>
							{t('signal_rollups.title')}
							:
						</Typography>
						{
							signalRollups.map((rollup) => (
								<ListItem
									key={rollup.id}
									style={{
										paddingTop: 0,
										paddingBottom: 2,
									}}
								>
									<Typography
										variant="caption"
										component="span"
										sx={{
											color: 'text.disabled',
											mr: 0.5,
										}}
									>
										{rollup.name}
										:
									</Typography>
									<strong>
										<OverflowTypography
											tooltipPlacement="bottom"
											noWrap
										>
											{`${formatRollupValue(rollup.value)}`}
										</OverflowTypography>
									</strong>

								</ListItem>
							))
						}
					</>
				) : t('messages.unexpected_error')}

			</CardContent>
			<CardActions>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={limsLink ? 4 : 6}
					>
						<ActionButton
							title={t('batch_redirect_modal.go_to_bach_tracing')}
							dataCy="mbai-time-window-card-view-batch-tracing-button"
							link={`/batch_traceability?${new URLSearchParams({
								line: equipmentTimeWindow.machine_id,
								equipment: equipmentTimeWindow.machine_sub_system.id,
								program: equipmentTimeWindow.programs[0]?.id,
								batch: equipmentTimeWindow.batches[0]?.id,
							}).toString()}`}
							icon={<AltRouteIcon sx={{transform: 'rotate(90deg)'}} />}
						/>
					</Grid>
					<Grid
						item
						xs={limsLink ? 4 : 6}
					>
						<ActionButton
							title={tLineManagement('equipment_dashboard.view')}
							dataCy="mbai-erp-batch-search-equipment-dashboard-button"
							link={`/equipment/${equipmentTimeWindow.machine_sub_system.id}?${new URLSearchParams({
								start_date: tStartWithMargin.toISOString(),
								end_date: tEndWithMargin.toISOString(),
								highlight_start_date: equipmentTimeWindow.t_start,
								highlight_end_date: equipmentTimeWindow.t_end,
								time_range: 'null',
							}).toString()}`}
							icon={<BarChartIcon />}
						/>
					</Grid>
					{
						limsLink && (
							<Grid
								item
								xs={4}
							>
								<ActionButton
									title={t('appbar_search_go_to_lims')}
									dataCy="mbai-time-window-card-go-to-lims-button"
									target="_blank"
									link={limsLink}
									icon={<ScienceIcon />}
								/>
							</Grid>
						)
					}
				</Grid>
			</CardActions>
		</BasicCard>
	)
}

export default EquipmentTimeWindowCard
