import {
	useEffect, useState,
} from 'react'
import {
	useUserContext, UserContextProps,
} from '../../containers/App/UserProvider'
import {useSearchParams} from 'react-router-dom'

export const useCurrentUser = () => {
	const [searchParams] = useSearchParams()
	const notificationType = searchParams.get('notification_type')
	const {currentUser} = useUserContext() as UserContextProps
	const [isSubscribedToLineConnectivity, setIsSubscribedToLineConnectivity] = useState<boolean>(false)
	const [isLineAdmin, setIsLineAdmin] = useState<boolean>(false)
	const [isAccountAdmin, setIsAccountAdmin] = useState<boolean>(false)
	const [isAncproEnabled, setIsAncproEnabled] = useState<boolean>(false)
	const [isSubscriptionAdmin, setIsSubscriptionAdmin] = useState<boolean>(false)
	const [isNotificationsDisabled, setIsNotificationsDisabled] = useState<boolean>(false)
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false)
	const [hasAgreedToPrivacyPolicy, setHasAgreedToPrivacyPolicy] = useState<boolean>(false)

	useEffect(() => {
		if (currentUser && currentUser.userprofile) {
			if (currentUser.userprofile.notification_preference_machine_connectivity_is_subscribed) {
				setIsSubscribedToLineConnectivity(true)
			} else {
				setIsSubscribedToLineConnectivity(false)
			}
			if (currentUser.userprofile.ancpro_instance) {
				setIsAncproEnabled(true)
			} else {
				setIsAncproEnabled(false)
			}
			if (currentUser.userprofile.roles.includes('Machine Admin')) {
				setIsLineAdmin(true)
			} else {
				setIsLineAdmin(false)
			}
			if (currentUser.userprofile.roles.includes('Account Admin')) {
				setIsAccountAdmin(true)
			} else {
				setIsAccountAdmin(false)
			}
			if (currentUser.userprofile.roles.includes('Subscription Admin')) {
				setIsSubscriptionAdmin(true)
			} else {
				setIsSubscriptionAdmin(false)
			}
			if (notificationType === 'machine' && !currentUser.userprofile.roles.includes('Machine Admin') || notificationType === 'admin' && !currentUser.userprofile.roles.includes('Account Admin')) {
				setIsNotificationsDisabled(true)
			} else {
				setIsNotificationsDisabled(false)
			}
			setHasAgreedToPrivacyPolicy(currentUser.userprofile.has_agreed_to_privacy_policy)
			setHasAgreedToTerms(currentUser.userprofile.has_agreed_to_terms_and_conditions)
		}
	}, [currentUser])

	return {
		isAncproEnabled,
		isLineAdmin,
		isSubscribedToLineConnectivity,
		isNotificationsDisabled,
		isAccountAdmin,
		isSubscriptionAdmin,
		currentUser,
		hasAgreedToTerms,
		hasAgreedToPrivacyPolicy,
	}
}
