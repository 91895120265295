import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'

interface LeftLegendWidth {
	signalId: string
	value: number | null
}

const initialState: {
	value: boolean
	isAnyGraphHovered: boolean
	isAnyGraphZoomed: boolean
	leftLegendWidths: {signalId: string, value: number | null}[]
	widestLeftLegend: number
	highestYValues: {signalId: string, value: number}[]
	highestYValueFromAllSignals: number
} = {
	value: true,
	isAnyGraphHovered: false,
	isAnyGraphZoomed: false,
	highestYValues: [],
	leftLegendWidths: [],
	widestLeftLegend: 0,
	highestYValueFromAllSignals: 0,
}

// Helper function to find the largest value in an array of LeftLegendWidth
const findWidestLeftLegend = (leftLegendWidths: LeftLegendWidth[]): number | null => leftLegendWidths.reduce<number | null>((max, unit) => {
	if (unit.value !== null && (max === null || unit.value > max)) {
		return unit.value
	}
	return max
}, null)

export const globalMode = createSlice({
	name: 'globalMode',
	initialState,
	reducers: {
		setGlobalMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			value: action.payload,
		}),
		setIsAnyGraphHovered: (state, action: PayloadAction<boolean>) => ({
			...state,
			isAnyGraphHovered: action.payload,
		}),
		addHighestYValue: (state, action: PayloadAction<{signalId: string, value: number}>) => {
			state.highestYValues.push(action.payload)
			state.highestYValueFromAllSignals = Math.max(...state.highestYValues.map((highestYValue) => highestYValue.value))
		},
		removeHighestYValue: (state, action: PayloadAction<string>) => {
			state.highestYValues = state.highestYValues.filter((highestYValue) => highestYValue.signalId !== action.payload)
			state.highestYValueFromAllSignals = Math.max(...state.highestYValues.map((highestYValue) => highestYValue.value))
		},
		setIsAnyGraphZoomed: (state, action: PayloadAction<boolean>) => ({
			...state,
			isAnyGraphZoomed: action.payload,
		}),
		addLeftLegendWidth: (state, action: PayloadAction<{ signalId: string; value: number | null }>) => {
			const newLeftLegendWidths = [
				...state.leftLegendWidths,
				{
					signalId: action.payload.signalId,
					value: action.payload.value,
				},
			]

			// Find the biggest value property
			const newWidestLeftLegend = findWidestLeftLegend(newLeftLegendWidths)

			return {
				...state,
				leftLegendWidths: newLeftLegendWidths,
				widestLeftLegend: newWidestLeftLegend as number, // Add this to the state if you want to store the max element
			}
		},
		removeLeftLegendWidth: (state, action: PayloadAction<string>) => {
			const newLeftLegendWidths = state.leftLegendWidths.filter((graphUnit) => graphUnit.signalId !== action.payload)
			const newWidestLeftLegend = findWidestLeftLegend(newLeftLegendWidths)

			return {
				...state,
				leftLegendWidths: newLeftLegendWidths,
				widestLeftLegend: newWidestLeftLegend as number,
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setGlobalMode,
	setIsAnyGraphHovered,
	addHighestYValue,
	removeHighestYValue,
	setIsAnyGraphZoomed,
	addLeftLegendWidth,
	removeLeftLegendWidth,
} = globalMode.actions
export default globalMode.reducer
