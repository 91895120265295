import {configureStore} from '@reduxjs/toolkit'
import {baseApi} from '../../apis/base.ts'
import batches from '../../redux/slices/batches'
import programs from '../../redux/slices/programs'
import timeseries from '../../redux/slices/timeseries'
import timemode from '../../redux/slices/timemode'
import signals from '../../redux/slices/signals'
import tsampling from '../../redux/slices/tsampling'
import views from '../../redux/slices/views'
import snackbar from '../../redux/slices/snackbar'
import anomalies from '../../redux/slices/anomalies'
import components from '../../redux/slices/components'
import signalsScreenModes from '../../redux/slices/signalsScreenModes'
import user from '../../redux/slices/user'
import tutorial from '../../redux/slices/tutorial'
import sliceTooltip from '../../redux/slices/sliceTooltip'
import globalMode from '../../redux/slices/globalMode'
import filterByClickDigitalTwin from '../../redux/slices/filterByClickDigitalTwin'
import batchFocusMode from '../../redux/slices/batchFocusMode.ts'
import edgeApiConnectionFormSlice from '../Dashboards/EdgeApi/redux/slices/edgeApiConnectionFormSlice.ts'
import edgeApiNavigationFormSlice from '../Dashboards/EdgeApi/redux/slices/edgeApiNavigationFormSlice.ts'

export const setupStore = (preloadedState: Object) => configureStore({
	reducer: {
		batches,
		programs,
		timeseries,
		timemode,
		signals,
		tSampling: tsampling,
		views,
		snackbar,
		anomalies,
		components,
		signalsScreenModes,
		user,
		tutorial,
		sliceTooltip,
		filterByClickDigitalTwin,
		globalMode,
		edgeApiConnectionForm: edgeApiConnectionFormSlice,
		edgeApiNavigationForm: edgeApiNavigationFormSlice,
		batchFocusMode,
		[baseApi.reducerPath]: baseApi.reducer,
	},
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
	}).concat(baseApi.middleware),
})

const store = setupStore({})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// setupListeners(store.dispatch)

// @ts-ignore
if (window.Cypress) {
	// @ts-ignore
	window.store = store
}
