import React, {
	FC, ReactElement, useEffect, useState,
} from 'react'
import StepsLineDashboard from './StepsLineDashboard'
import {useDispatch} from 'react-redux'
import {
	useUserContext, UserContextProps,
} from '../../../containers/App/UserProvider'
import SkipTutorial from './SkipTutorial'
import {updateTutorial} from '../../../redux/slices/tutorial'
import {useTutorial} from '../../hooks/useTutorial'
import {useCurrentUser} from '../../hooks/useCurrentUser'

const OnBoardingTutorial: FC = (): ReactElement | null => {
	const {pathname} = window.location
	const steps = useTutorial()
	const dispatch = useDispatch()
	const {currentUser} = useUserContext() as UserContextProps
	const [hasEffect, setHasEffect] = useState<boolean>(false)
	const [initialStep, setInitialStep] = useState<number>(0)
	const [isExitConfirmOpen, setIsExitConfirmOpen] = useState<boolean>(false)
	const [isMainTutorialOpen, setIsMainTutorialOpen] = useState<boolean>(false)
	const {
		hasAgreedToTerms,
		hasAgreedToPrivacyPolicy,
	} = useCurrentUser()

	// Sets the initial step. Should run only once
	useEffect(() => {
		// If tutorial is inactive, set the initial step number and active the tutorial
		if (!hasEffect && currentUser && currentUser.userprofile) {
			if (currentUser.userprofile.onboarding_tutorial_last_step_completed < steps.length - 1) {
				const firstStep = currentUser.userprofile.onboarding_tutorial_last_step_completed < -1 ? 0 : currentUser.userprofile.onboarding_tutorial_last_step_completed + 1
				setInitialStep(firstStep)
				if (!currentUser.userprofile.onboarding_tutorial_skipped) {
					setTimeout(() => {
						setIsMainTutorialOpen(true)
					}, 2000)
				}
				dispatch(updateTutorial({
					tutorial_skipped: currentUser.userprofile.onboarding_tutorial_skipped,
					last_step_completed: currentUser.userprofile.onboarding_tutorial_last_step_completed,
				}))
			}
			setHasEffect(true)
		}
	}, [currentUser])

	const handleSkipTutorialOpen = () => {
		setIsExitConfirmOpen(true)
		setIsMainTutorialOpen(false)
	}

	const handleMainLineTutorialOpen = (both: boolean) => {
		if (!both) {
			setIsMainTutorialOpen(true)
		}
		setIsExitConfirmOpen(false)
	}

	return (pathname === '/' || pathname.includes('lines_overview')) && hasAgreedToTerms && hasAgreedToPrivacyPolicy ? (
		<>
			<StepsLineDashboard
				steps={steps}
				initialStep={initialStep}
				open={isMainTutorialOpen}
				onLineDashboardClose={handleSkipTutorialOpen}
			/>
			<SkipTutorial
				open={isExitConfirmOpen}
				onSkipTutorialClose={handleMainLineTutorialOpen}
			/>
		</>
	) : null
}

export default OnBoardingTutorial
