import {
	FC, ReactElement,
} from 'react'
import {
	Box, CircularProgress, circularProgressClasses,
} from '@mui/material'

interface MBAILoadingSpinnerProps {
    size?: 'xsmall' | 'small' | 'medium' | 'large'
}

const MBAILoadingSpinner: FC<MBAILoadingSpinnerProps> = (
	{
		size = 'medium',
		...rest
	},
): ReactElement => {
	const newSize = size === 'xsmall' ? 12 : size === 'small' ? 20 : size === 'medium' ? 40 : 50
	return (
		<Box
			sx={{position: 'relative'}}
			data-testid="mbai-loading-spinner-component"
			{...rest}
		>
			<Box sx={{position: 'relative'}}>
				<CircularProgress
					variant="determinate"
					sx={{
						color: 'divider',
						position: 'absolute',
					}}
					size={newSize}
					thickness={size === 'xsmall' ? 5 : 2}
					value={100}
				/>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{
						color: 'primary.main',
						animationDuration: '550ms',
						position: 'absolute',
						left: 0,
						[`& .${circularProgressClasses.circle}`]: {strokeLinecap: 'round'},
					}}
					size={newSize}
					thickness={size === 'xsmall' ? 5 : 2}
				/>
			</Box>
		</Box>
	)
}

export default MBAILoadingSpinner
