import {
	FC, useState,
} from 'react'
import {
	Box, Chip, Paper, Popper, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import HorizontalScrollingContainer from '../../../shared/components/HorizontalScrollingContainer.tsx'
import SearchLineResults from './SearchLineResults.tsx'
import SearchEquipmentResults from './SearchEquipmentResults.tsx'
import ScrollbarContainer from '../../../shared/components/ScrollbarContainer.tsx'
import {
	UserContextProps, useUserContext,
} from '../../App/UserProvider.tsx'
import SearchEquipmentTimeWindowResults from './SearchEquipmentTimeWindowResults.tsx'

interface AppbarSearchStandardProps {
    anchorEl: HTMLElement | null,
    minWidth: number,
    handleClose: () => void,
    searchTerm: string,
}

const AppbarSearchStandard: FC<AppbarSearchStandardProps> = (
	{
		anchorEl,
		minWidth,
		handleClose,
		searchTerm,
	},
) => {
	const [selectedChips, setSelectedChips] = useState<Set<string>>(new Set())

	const [t] = useCustomTranslation('common')

	const {currentUser} = useUserContext() as UserContextProps

	const searchForEquipmentTimeWindowEnabledFor = currentUser?.userprofile?.search_for_equipment_time_window_enabled_for || []

	const handleChipClick = (chipKey: string) => {
		setSelectedChips((prevSelectedChips) => {
			const newSelectedChips = new Set(prevSelectedChips)
			if (newSelectedChips.has(chipKey)) {
				newSelectedChips.delete(chipKey)
			} else {
				newSelectedChips.add(chipKey)
			}
			return newSelectedChips
		})
	}

	return (
		<Popper
			id="mbai-standard-search-popper"
			open
			anchorEl={anchorEl}
			placement="bottom-start"
			style={{
				zIndex: 1300,
				minWidth,
				maxWidth: '70%',
				maxHeight: '70vh',
			}}
		>
			<Paper
				sx={{p: 2}}
				elevation={8}
			>
				<Typography
					variant="h6"
					color="text.secondary"
					// Add some spacing to the bottom
					sx={{mb: 1}}
				>
					{t('appbar_search_categories.title')}
				</Typography>
				<HorizontalScrollingContainer>
					<Box sx={{
						display: 'flex',
						gap: 1,
						mb: 1,
					}}
					>
						<Chip
							key="lines"
							label={t('appbar_search_categories.lines')}
							onClick={() => handleChipClick('lines')}
							color={selectedChips.has('lines') ? 'primary' : 'default'}
						/>
						<Chip
							key="equipments"
							label={t('appbar_search_categories.equipments')}
							onClick={() => handleChipClick('equipments')}
							color={selectedChips.has('equipments') ? 'primary' : 'default'}
						/>
						{
							searchForEquipmentTimeWindowEnabledFor.map((equipmentSignal) => (
								<Chip
									key={equipmentSignal}
									label={t(`appbar_search_categories.${equipmentSignal}`)}
									onClick={() => handleChipClick(equipmentSignal)}
									color={selectedChips.has(equipmentSignal) ? 'primary' : 'default'}
								/>
							))
						}

					</Box>
				</HorizontalScrollingContainer>
				<ScrollbarContainer
					maxHeight="calc(100vh - 200px)"
				>
					{
						searchTerm ? (
							<Box>
								{(selectedChips.size === 0 || selectedChips.has('lines')) && (
									<Box onClick={handleClose}>
										<SearchLineResults searchTerm={searchTerm} />
									</Box>
								)}
								{(selectedChips.size === 0 || selectedChips.has('equipments')) && (
									<Box onClick={handleClose}>
										<SearchEquipmentResults searchTerm={searchTerm} />
									</Box>
								)}
								{
									searchForEquipmentTimeWindowEnabledFor.map((equipmentSignal) => (
										(selectedChips.size === 0 || selectedChips.has(equipmentSignal)) && (
											<Box onClick={handleClose}>
												<SearchEquipmentTimeWindowResults
													searchTerm={searchTerm}
													equipmentSignal={equipmentSignal}
												/>
											</Box>
										)))
								}
							</Box>
						) : null
					}
				</ScrollbarContainer>
			</Paper>
		</Popper>
	)
}

export default AppbarSearchStandard
