import {
	FC, useEffect, useMemo, useState,
} from 'react'
import {
	scaleBand, scaleLinear, ScaleTime,
} from 'd3'
import {Box} from '@mui/material'
import {getColorFromProgramClassification} from '../../../../util-functions'
import {useTheme} from '@mui/material/styles'
import D3ProductionTimelineRect from '../../../../shared/components/d3Components/productionTimeline/ProductionTimelineRect'
import TimeXAxis from '../../../../shared/components/d3Components/TimeXAxis'
import LinearYAxis from '../../../../shared/components/d3Components/LinearYAxis'
import D3DailyProductionBarTooltip from './D3DailyProductionBarTooltip'
import {programClassifications} from '../../../../shared/constants/programClassifications'

interface TransformedData {
	date: string;
	[classification: string]: number | string; // Additional dynamic keys for classifications
  }

interface AvailabilityData {
	date: string;
	classification: string;
	duration: number;
	id: string;
}

interface D3StackedBarChartProps {
	data: AvailabilityData[];
	width: number
	height: number
	margin: {
		top: number
		right: number
		bottom: number
		left: number
	}
	summarizeBy?: string
	tooltip?: boolean
}

interface D3DailyProductionBarTooltipProps {
	open: boolean
	color: string
	seriesKey: string
	value: number
	percentage: number
}

type ProgramClassification = (typeof programClassifications)[number];

const D3DailyProductionBar: FC<D3StackedBarChartProps> = ({
	data,
	width,
	height,
	summarizeBy,
	tooltip,
	margin,
}) => {
	const theme = useTheme()
	const [tooltipData, setTooltipData] = useState<D3DailyProductionBarTooltipProps>({
		open: false,
		color: '',
		seriesKey: '',
		value: 0,
		percentage: 0,
	})
	const [transformedData, setTransformedData] = useState<TransformedData[]>([])

	useEffect(() => {
		const reducedData = data.reduce((acc, curr) => {
			const existingDay = acc.find((day) => day.date === curr.date)
			if (existingDay) {
				existingDay[curr.classification] = curr.duration
			} else {
				acc.push({
					date: curr.date,
					[curr.classification]: curr.duration,
				})
			}
			return acc
		}, [] as TransformedData[])
		setTransformedData(reducedData)
	}, [data])

	const xScale = scaleBand()
		.domain(transformedData.map((d) => d.date))
		.range([0, width])
		.padding(0.3)

	const yScale = scaleLinear()
		.domain(summarizeBy === 'week' ? [0, 168] : [0, 24])
		.range([height, 0])

	const getUniqueKeysWithoutDate = ((dataArray: TransformedData[]) => {
		const uniqueKeys = new Set<string>() // Define the type of elements in the Set explicitly

		dataArray.forEach((item) => {
			Object.keys(item).forEach((key) => {
				if (key !== 'date') {
					uniqueKeys.add(key)
				}
			})
		})

		return Array.from(uniqueKeys)
	})

	const batchKeys = useMemo(() => getUniqueKeysWithoutDate(transformedData).sort((a, b) => {
		const indexA = programClassifications.indexOf(a as ProgramClassification)
		const indexB = programClassifications.indexOf(b as ProgramClassification)

		// If both keys are found in programClassifications, sort by their order
		if (indexA !== -1 && indexB !== -1) {
			return indexA - indexB
		}

		// If only one key is found, prioritize it
		if (indexA !== -1) return -1
		if (indexB !== -1) return 1

		// If neither key is found, maintain original order (or add other criteria)
		return 0
	}), [transformedData])

	const onMouseEnterHandler = (key: string, data: TransformedData) => {
		const sum = Object.keys(data)
		  .filter((k) => k !== 'date')
		  .reduce((total, k) => total + (data[k] as number), 0)
		  const rawValue = data[key] as number // Ensure this is a number
		  const percentage = (rawValue / sum) * 100

		setTooltipData({
			open: true,
			color: getColorFromProgramClassification(key, theme),
			seriesKey: key,
			value: rawValue,
			percentage: parseFloat(percentage.toFixed(1)),
		})
	}

	const onMouseLeaveHandler = () => {
		setTooltipData({
			open: false,
			color: '',
			seriesKey: '',
			value: 0,
			percentage: 0,
		})
	}

	const tickValuesDay = [0, 4, 8, 12, 16, 20, 24]
	const tickValuesWeek = [0, 24, 48, 72, 96, 120, 144, 168]

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100%',
			}}
			data-testid="mbai-d3daily-production-bar"
		>
			<svg
				width="100%"
				height="100%"
			>
				<g className="x-axis" transform={`translate(${margin.left}, ${margin.top + height})`}>
					<TimeXAxis
						width={width}
						xScale={xScale as unknown as ScaleTime<number, number>}
						height={height}
						displayInUTC
						summarizeBy={summarizeBy}
					/>
				</g>

				<g className="y-axis" transform={`translate(${margin.left}, ${margin.top})`}>
					<LinearYAxis
						yScale={yScale}
						width={width}
						tickValues={summarizeBy === 'day' ? tickValuesDay : tickValuesWeek}
						tickFormat
					/>

				</g>
				<g transform={`translate(${margin.left}, ${margin.top})`}>
					{transformedData.map((d) => {
						let cumulativeHeight = 0
						return (
							batchKeys.map((key) => {
								if (d[key]) {
									const value = d[key] as number
									const rectHeight = yScale(0) - yScale(value) // Height of the current segment
									const y = yScale(cumulativeHeight + value) // Y-position of the current segment
									cumulativeHeight += value // Update the cumulative height
									return (
										<D3ProductionTimelineRect
											keyProp={`${d.date}-${key}`}
											mainKey={key as string}
											x={xScale(d.date as unknown as string) as number}
											y={y}
											width={xScale.bandwidth() as number}
											rectHeight={rectHeight}
											fill={getColorFromProgramClassification(key, theme)}
											hours={value}
											onMouseEnter={() => onMouseEnterHandler(key, d)}
											onMouseLeave={onMouseLeaveHandler}
											vertical
										/>
									)
								}
								return null
							}))
					})}
				</g>
			</svg>
			{tooltip ? <D3DailyProductionBarTooltip {...tooltipData} /> : null}
		</Box>
	)
}

export default D3DailyProductionBar
