import React, {
	FC, ReactElement,
} from 'react'
import {
	Card, CardProps,
} from '@mui/material'

const BasicCard: FC<CardProps> = (
	{
		children, id, ...rest
	},
): ReactElement => (
	<Card
		data-testid="mbai-main-basic-card"
		id={id || undefined}
		{...rest}
	>
		{children}
	</Card>
)

export default BasicCard
