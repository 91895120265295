import React, {
	FC, ReactElement,
} from 'react'
import {
	Box, Skeleton,
} from '@mui/material'
import BasicDashboardContainer from '../../BasicDashboardContainer'

const LineManagementLoader: FC = (): ReactElement => {
	const height = '400px'
	return (
		<BasicDashboardContainer>
			<Box
				data-testid="mbai-main-machine-management-page-loader"
				sx={{
					width: '100%',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						mt: 1,
					}}
				>
					<Skeleton
						sx={{
							width: '100%',
							maxWidth: '300px',
							height: '16px',
						}}
						variant="rounded"
					/>
					<Box
						sx={{display: 'flex'}}
					>
						{
							[...Array(4)].map((item) => (
								<Skeleton
									key={item}
									variant="rounded"
									sx={{
										width: '200px',
										height: '24px',
										mx: 1,
									}}
								/>
							))
						}
					</Box>
				</Box>
				<Box
					sx={{
						pt: 3,
						height: 'calc(100vh - 165px)',
						display: 'flex',
					}}
				>
					<Skeleton
						variant="rounded"
						sx={{
							width: '133%',
							height,
						}}
					/>
					<Skeleton
						variant="rounded"
						sx={{
							width: 'calc(67% - 24px)',
							height,
							ml: 2,
						}}
					/>
				</Box>
			</Box>
		</BasicDashboardContainer>
	)
}

export default LineManagementLoader
