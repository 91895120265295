import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'
import {timeRangeOptions} from '../../shared/constants/testdata/signalPageTimeRangeOptions'
import {TimeRangeInterface} from '../../shared/interfaces/timerange'

type InitStateProp = {
    relativeMode: boolean,
    startDate: string,
    endDate: string,
    timeSelector: TimeRangeInterface
}

const start = new Date()
const startDate = start.toISOString()

const initialState: InitStateProp = {
	relativeMode: false,
	startDate,
	endDate: startDate,
	timeSelector: timeRangeOptions[3],
}

export const timeseries = createSlice({
	name: 'timeseries',
	initialState,
	reducers: {
		setBatchesStartDate: (state, action: PayloadAction<string>) => ({
			...state,
			startDate: action.payload,
		}),
		setBatchesEndDate: (state, action: PayloadAction<string>) => ({
			...state,
			endDate: action.payload,
		}),
		setRelativeMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			relativeMode: action.payload,
		}),
		setTimeSelector: (state, action: PayloadAction<TimeRangeInterface>) => ({
			...state,
			timeSelector: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {
	setBatchesStartDate, setBatchesEndDate, setRelativeMode, setTimeSelector,
} = timeseries.actions
export default timeseries.reducer
