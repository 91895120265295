import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'

const initialState: {value: boolean} = {value: true}
export const timemode = createSlice({
	name: 'timemode',
	initialState,
	reducers: {
		setGlobalTimeMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			value: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setGlobalTimeMode} = timemode.actions
export default timemode.reducer
