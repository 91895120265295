import {baseApi} from './base'
import {BaseFetchResultsInterface} from '../shared/interfaces/base'
import {AnomalyWithReasonInterface} from '../shared/interfaces/anomaly'

const anomaliesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getAnomalies: builder.query<BaseFetchResultsInterface<AnomalyWithReasonInterface>, {programId?: string, machineId?: string,
			machineSubSystemId?: string, offset: number, limit: number}>({
				query: ({
					programId, machineId, machineSubSystemId, offset, limit,
				}) => {
					const queryParameters = []
					if (programId) queryParameters.push(`batch__program=${programId}`)
					if (machineId) queryParameters.push(`batch__machine=${machineId}`)
					if (machineSubSystemId) queryParameters.push(`batch__program__machine_sub_system=${machineSubSystemId}`)
					queryParameters.push(`offset=${offset}`)
					queryParameters.push(`limit=${limit}`)

					const queryString = queryParameters.join('&')
					const url = `anomalies/?${queryString}`

					return {url}
				},
				transformResponse: (response: BaseFetchResultsInterface<AnomalyWithReasonInterface>) => response,
				extraOptions: {refetchOnMountOrArgChange: true},
			}),
		getAnomalyById: builder.query<AnomalyWithReasonInterface, string>({query: (id) => `anomalies/${id}/`}),

	}),
	overrideExisting: false,
})

export const {
	useGetAnomaliesQuery,
	useLazyGetAnomaliesQuery,
	useGetAnomalyByIdQuery,
} = anomaliesApi
