import {
	createSlice, PayloadAction,
} from '@reduxjs/toolkit'

const initialState: {value: boolean} = {value: false}
export const batchFocusMode = createSlice({
	name: 'batchFocusMode',
	initialState,
	reducers: {
		setBatchFocusMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			value: action.payload,
		}),
	},
})

// Action creators are generated for each case reducer function
export const {setBatchFocusMode} = batchFocusMode.actions
export default batchFocusMode.reducer
