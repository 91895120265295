import {
	BaseSyntheticEvent,
	FC, ReactElement, useState, MouseEvent,
} from 'react'
import {
	Tooltip, Typography, IconButton, DialogContent, Divider, Box, TextField,
} from '@mui/material'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import BasicModal from '../../components/BasicModal'
import LoadingButton from '@mui/lab/LoadingButton'
import {useDispatch} from 'react-redux'
import {
	useUserContext, UserContextProps,
} from '../../../containers/App/UserProvider'
import {useUpdateUserMutation} from '../../../apis/user.ts'
import {SnackbarPropsInterface} from '../../interfaces/notification'
import {useSnackbar} from '../../../shared/hooks/useSnackbar'
import {useAuth} from '../../../shared/hooks/useAuth'
import {useTutorial} from '../../../shared/hooks/useTutorial'
import {useCustomTranslation} from '../../hooks/useCustomTranslation'
import {useNavigate} from 'react-router-dom'
import {updateTutorial} from '../../../redux/slices/tutorial'

const UpdateTutorial: FC = (): ReactElement | null => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [nextStep, setNextStep] = useState<number>(0)
	const [showNotification] = useSnackbar()
	const {updateLocalUser} = useAuth()
	const steps = useTutorial()
	const [t] = useCustomTranslation('tutorial')
	const [t2] = useCustomTranslation('common')
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleChange = (event: BaseSyntheticEvent) => {
		setNextStep(event.target.value)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const {currentUser} = useUserContext() as UserContextProps
	const open = Boolean(anchorEl)

	const [updateUser, data] = useUpdateUserMutation()

	const handleUserUpdate = (value: number | boolean, updateField: string) => {
		updateUser({[updateField]: value})
			.unwrap()
			.then((data) => {
				showNotification({
					message: t2('messages.successful_update'),
					severity: 'success',
				})
				dispatch(updateTutorial({
					tutorial_skipped: data.user.userprofile.onboarding_tutorial_skipped,
					last_step_completed: data.user.userprofile.onboarding_tutorial_last_step_completed,
					exit_confirm_open: false,
				}))
				updateLocalUser(data.user)
				handleClose()
				navigate('lines_overview')
			})
			.catch(() => {
				const errorObject: SnackbarPropsInterface = {
					message: t2('messages.unexpected_error'),
					severity: 'error',
				}
				showNotification(errorObject)
			})
	}

	return (
		currentUser && currentUser.userprofile && currentUser.userprofile.onboarding_tutorial_skipped ? (
			<div>
				<Tooltip title={t('update_tutorial.tooltip_title')}>
					<IconButton
						onClick={handleClick}
						data-testid="mbai-main-update-tutorial-button"
					>
						<HelpTwoToneIcon />
					</IconButton>
				</Tooltip>
				<BasicModal
					onClose={handleClose}
					title={t('update_tutorial.title')}
					open={open}
					wrappers={false}
				>
					<>
						<Divider />
						<DialogContent
							sx={{display: 'none'}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-end',
								}}
							>
								<Typography
									variant="caption"
									sx={{color: 'text.disabled'}}
								>
									{t('update_tutorial.last_completed_step')}
								</Typography>
								<Typography>
									{currentUser.userprofile.onboarding_tutorial_last_step_completed}
								</Typography>
							</Box>
							<Divider
								sx={{
									mt: 1,
									mb: 1.5,
								}}
							/>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Box>
									<TextField
										placeholder="Step number"
										variant="outlined"
										label={t('update_tutorial.step_number')}
										onChange={handleChange}
										value={nextStep}
										size="small"
										sx={{mr: 1}}
										inputProps={{
											inputMode: 'numeric',
											pattern: '[0-9]*',
										}}
										error={nextStep < -3 || nextStep > steps.length - 1}
										helperText={`${steps.length - currentUser.userprofile.onboarding_tutorial_last_step_completed} ${t('update_tutorial.remaining_steps')}`}
									/>
								</Box>
								<LoadingButton
									variant="contained"
									color="primary"
									size="small"
									loading={data.isLoading && data.originalArgs.hasOwnProperty('onboarding_tutorial_last_step_completed')}
									onClick={() => handleUserUpdate(nextStep - 1, 'onboarding_tutorial_last_step_completed')}
									sx={{maxHeight: '40px'}}
								>
									{t('update_tutorial.go_to_step')}
								</LoadingButton>
							</Box>
						</DialogContent>
						<Divider />
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'bottom',
								}}
							>
								<Typography>
									{t('update_tutorial.tutorial_skipped')}
								</Typography>
								<LoadingButton
									variant="contained"
									color="primary"
									size="small"
									loading={data.isLoading && data.originalArgs.hasOwnProperty('onboarding_tutorial_skipped')}
									onClick={() => handleUserUpdate(!currentUser.userprofile?.onboarding_tutorial_skipped, 'onboarding_tutorial_skipped')}
								>
									{t('update_tutorial.unskip')}
								</LoadingButton>
							</Box>
						</DialogContent>
						<Divider />
					</>
				</BasicModal>
			</div>
		) : null
	)
}

export default UpdateTutorial
