import React, {
	FC, ReactElement, useEffect, useState, useCallback,
} from 'react'
import {Steps} from 'intro.js-react'
import 'intro.js/introjs.css'
import '../../../index.css'
import {useCustomTranslation} from '../../hooks/useCustomTranslation'
import {useDispatch} from 'react-redux'
import {SnackbarPropsInterface} from '../../interfaces/notification'
import {useUpdateUserMutation} from '../../../apis/user.ts'
import {useAuth} from '../../hooks/useAuth'
import {useSnackbar} from '../../hooks/useSnackbar'
import {updateTutorial} from '../../../redux/slices/tutorial'
import {StepsInterface} from '../../interfaces/steps'
import {
	useUserContext, UserContextProps,
} from '../../../containers/App/UserProvider'

interface StepsLineDashboardProps {
    initialStep: number
    steps: StepsInterface[]
	open: boolean
	onLineDashboardClose: () => void
}

const StepsLineDashboard: FC<StepsLineDashboardProps> = (
	{
		initialStep,
		steps,
		open,
		onLineDashboardClose,
	},
): ReactElement | null => {
	const dispatch = useDispatch()
	const {updateLocalUser} = useAuth()
	const {currentUser} = useUserContext() as UserContextProps
	const [t] = useCustomTranslation('tutorial')
	const [updateUser] = useUpdateUserMutation()
	const [currentStep, setCurrentStep] = useState<number>(initialStep)
	const [showNotification] = useSnackbar()
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => {
		setIsMounted(true)

		return () => {
			setIsMounted(false)
		}
	}, [])

	const updateLastStepCompleted = useCallback((step: number) => {
		updateUser({onboarding_tutorial_last_step_completed: step})
			.unwrap()
			.then((data) => {
				updateLocalUser(data.user)
			})
			.catch(() => {
				const errorObject: SnackbarPropsInterface = {
					message: 'Unexpected error!',
					severity: 'error',
				}
				showNotification(errorObject)
				return false
			})
	}, [updateUser, updateLocalUser, showNotification])

	const handleComplete = useCallback(() => {
		updateLastStepCompleted(currentStep)
	}, [currentStep, updateLastStepCompleted])

	const handleBeforeChange = useCallback((newStepIndex: number) => {
		setCurrentStep(newStepIndex)
		if (newStepIndex > currentStep) {
			updateLastStepCompleted(currentStep)
		} else if (newStepIndex < currentStep) {
			updateLastStepCompleted(newStepIndex - 1)
		}
	}, [currentStep, updateLastStepCompleted])

	// Handle closing the main tutorial. Closes the main tutorial and opens the ExitConfirm Component
	// If steps are between 0 - 8
	const handleExit = useCallback((stepIndex: number) => {
		if (stepIndex >= 0 && stepIndex < steps.length - 1) {
			dispatch(updateTutorial({
				tutorial_skipped: currentUser?.userprofile?.onboarding_tutorial_skipped,
				last_step_completed: currentUser?.userprofile?.onboarding_tutorial_last_step_completed,
			}))
			onLineDashboardClose()
		} else if (stepIndex >= steps.length - 1) {
			// If we've reached the last step, close the main tutorial
			onLineDashboardClose()
		}
	}, [currentUser, dispatch, onLineDashboardClose, steps.length])

	useEffect(() => {
		setCurrentStep(initialStep)
	}, [initialStep])

	return (
		<Steps
			data-testid="mbai-main-step-line-dashboard"
			enabled={open}
			steps={steps}
			initialStep={initialStep}
			onExit={(stepIndex) => isMounted && handleExit(stepIndex)}
			onComplete={handleComplete}
			onChange={(index) => handleBeforeChange(index)}
			options={{
				buttonClass: 'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary',
				tooltipClass: 'mbaiTutorialTooltip',
				exitOnEsc: false,
				exitOnOverlayClick: false,
				nextLabel: t('next'),
				prevLabel: t('back'),
				doneLabel: t('finish'),
			}}
		/>
	)
}

export default StepsLineDashboard
