import {createTheme} from '@mui/material/styles'
import {sharedTheme} from './sharedTheme.ts'

declare module '@mui/material/styles' {
    // allow configuration using `createTheme`
    interface TypeBackground {
        fadedOpaque: string
        scrollbar: string
    }

	interface Palette {
        smallBoxShadow: string
		mediumBoxShadow: string
		borderWidth: number
		borderColor: string
    }

    interface PaletteOptions {
        smallBoxShadow?: string
		mediumBoxShadow?: string
		borderWidth?: number
		borderColor?: string
    }
}

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {main: '#923e4b'},
		secondary: {main: '#335c81'},
		background: {
			default: '#edf0f7',
			paper: 'white',
			fadedOpaque: 'rgba(0,0,0,.05)',
			scrollbar: 'rgba(0,0,0,.2)',
		},
		smallBoxShadow: '0px 1px 0px rgba(0, 0, 0, 0.2)',
		mediumBoxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
		borderWidth: 0.5,
		borderColor: 'rgba(0, 0, 0, 0.3)',
	},
	...sharedTheme,
	components: {
		...sharedTheme.components,
		MuiButton: {
			styleOverrides: {
				...sharedTheme.components?.MuiButton?.styleOverrides,
				contained: {
					boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
					'&:hover': {boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)'},
				},
			},
			defaultProps: sharedTheme.components?.MuiButton?.defaultProps,
		},
	},
})
