import {FC} from 'react'
import BasicCard from '../../../../shared/components/BasicCard'
import {
	Box, CardContent, CardHeader, Typography,
} from '@mui/material/'
import {D3DailyProductionBarTooltipProps} from '../../../../shared/interfaces/d3DailyProductionBarTooltipProps'
import {classificationTranslationMapping} from '../../../../util-functions'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const D3DailyProductionBarTooltip:FC<D3DailyProductionBarTooltipProps> = ({
	open, color, seriesKey, value, percentage,
}) => {
	const [t] = useCustomTranslation('common')

	return (
		<BasicCard
			sx={{
				display: open ? 'block' : 'none',
				position: 'fixed',
				top: '10px', // Set the tooltip in the top-left corner
				right: '10px',
				zIndex: 10000,
				textAlign: 'left',
				color,
				borderColor: 'primary.main',
				border: 3,
			}}
			data-testid="mbai-d3daily-production-bar-tooltip"
		>
			<CardHeader
				title={classificationTranslationMapping(seriesKey)}
			/>
			<CardContent
				sx={{pb: '12px !important'}}
			>
				<Box
					sx={{
						display: 'flex', flexDirection: 'column',
					}}
				>
					<Typography
						variant="caption"
						component="span"
						sx={{
							color: 'text.disabled',
							mr: 0.5,
						}}
					>
						{`${t('duration')}: ${value.toFixed(1)}`}
					</Typography>
					<Typography
						variant="caption"
						component="span"
						sx={{
							color: 'text.disabled',
							mr: 0.5,
						}}
					>
						{`${t('percentage')}: ${percentage}%`}
					</Typography>
				</Box>
			</CardContent>
		</BasicCard>
	)
}

export default D3DailyProductionBarTooltip
