import {
	FC, ReactElement,
} from 'react'
import {LoadingButton} from '@mui/lab'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const DocumentationButton: FC = (): ReactElement => {
	const [t] = useCustomTranslation('edge_api')
	return (
		<LoadingButton
			variant="contained"
			color="primary"
			onClick={() => window.open('https://docs.montblanc.ai')}
			sx={{mt: 1}}
		>
			{t('buttons.go_to_documentation')}
		</LoadingButton>
	)
}

export default DocumentationButton
