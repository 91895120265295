import React, {
	FC, ReactElement,
} from 'react'
import {
	Box, Skeleton,
} from '@mui/material'
import BasicDashboardContainer from '../../BasicDashboardContainer'

const BatchTraceabilityLoader: FC = (): ReactElement => (
	<BasicDashboardContainer>
		<Box
			data-testid="mbai-main-batch-traceability-page-loader"
			sx={{
				width: '100%',
				height: '100vh',
			}}
		>
			<Skeleton
				sx={{
					width: '100%',
					maxWidth: '400px',
				}}
			/>
			<Box
				sx={{mt: 4}}
			>
				<Skeleton
					variant="rounded"
					sx={{
						width: '100%',
						height: 'calc(100vh - 150px)',
					}}
				/>
			</Box>
		</Box>
	</BasicDashboardContainer>
)

export default BatchTraceabilityLoader
