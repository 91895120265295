import React, {
	FC, ReactElement, StrictMode, useCallback,
} from 'react'
import {
	styled, Theme,
} from '@mui/material/styles'
import {
	List, Box, IconButton,
} from '@mui/material'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation'
import MuiDrawer from '@mui/material/Drawer'
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'
// Uncomment to add HubspotChat
// import HubspotChat from '../../../shared/components/HubspotChat'
import DrawerListItem from './DrawerListItem'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import {makeStyles} from '@material-ui/core/styles'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import RouterIcon from '@mui/icons-material/Router'
import MenuIcon from '@mui/icons-material/Menu'
import {useLogo} from '../../../shared/hooks/useLogo.ts'
import {NavLink} from 'react-router-dom'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'

const drawerWidth: number = 240

const openedMixin = (theme: Theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
		duration: '250ms',
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme) => ({
	transition: theme.transitions.create('width', {
		easing: 'cubic-bezier(0.4, 0.0, 1, 1)',
		duration: '200ms',
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`},
})

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({
	theme, open,
}) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
			duration: '250ms',
		}),
		overflowX: 'hidden',
		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.background.default,
			...openedMixin(theme),
		},
	}),
	...(!open && {
		transition: theme.transitions.create('width', {
			easing: 'cubic-bezier(0.4, 0.0, 1, 1)',
			duration: '200ms',
		}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`},
		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.background.default,
			...closedMixin(theme),
		},
	}),
}))

const useStyles = makeStyles(() => ({rotatedIcon: {transform: 'rotate(90deg)'}}))

type Props = {
    open: boolean,
	onDrawerOpen: () => void
}

const MBAIMainDrawer: FC<Props> = ({
	open, onDrawerOpen,
}): ReactElement => {
	const {fullLogo} = useLogo()
	const [t] = useCustomTranslation('common')
	const [t2] = useCustomTranslation('settings')
	const [tDashboards] = useCustomTranslation('dashboards')
	const handleDrawerOpen = useCallback(() => {
		onDrawerOpen()
	}, [])

	const classes = useStyles()

	const listItemsTop = [
		{
			title: t('sidebar.lines_overview'),
			icon: <MonitorHeartTwoToneIcon />,
			link: '/lines_overview?view=grid',
			id: 'mbai-main-line-overview-link',
		},
		{
			title: t('sidebar.batch_traceability'),
			icon: <AltRouteIcon className={classes.rotatedIcon} />,
			link: '/batch_traceability',
			id: 'mbai-main-batch-traceability-link',
		},
		{
			title: tDashboards('dashboards'),
			icon: <WebAssetIcon />,
			link: '/dashboards',
			id: 'mbai-main-dashboard-link',

		},
		{
			title: t('signals.plural'),
			icon: <TrendingUpIcon />,
			link: '/all-signals',
			id: 'mbai-all-signals-link',
		},
		{
			title: t('anomalies'),
			icon: <TroubleshootIcon />,
			link: '/anomalies',
			id: 'mbai-main-anomalies-link',
		},
	]

	const listItemsBottom = [
		{
			title: t('connections'),
			icon: <RouterIcon />,
			link: '/connections',
			id: 'mbai-main-connections-link',
		},

		{
			title: t2('title'),
			icon: <SettingsTwoToneIcon />,
			link: '/settings',
			id: 'mbai-main-settings-link',
		},
	]

	return (
		<StrictMode>
			<Drawer
				variant="permanent"
				open={open}
				sx={{
					'& .MuiPaper-root': {
						backgroundColor: 'background.paper',
						borderRight: 1,
						borderColor: 'divider',
					},
					'& .MuiSvgIcon-root': {fontSize: '1.1rem'},
					display: {
						xs: 'none',
						sm: 'block',
					},
				}}
				data-testid="mbai-layout-drawer-component"
				id="mbai-main-layout-drawer-component"
			>
				<Box
					sx={{
						pl: 1.4,
						py: 1.5,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<IconButton
						size="large"
						onClick={handleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					<NavLink to="/">
						<img
							src={fullLogo}
							alt="MontBlancAI MainLogo"
							style={{
								display: open ? 'block' : 'none',
								maxWidth: '125px',
							}}
						/>
					</NavLink>
				</Box>
				<List>
					{listItemsTop.map((text, index) => (
						<DrawerListItem
							key={text.id}
							text={text}
							index={index}
							open={open}
							idProp={text.id}
						/>
					))}
				</List>
				<List
					sx={{marginTop: 'auto'}}
				>
					{listItemsBottom.map((text, index) => (
						<DrawerListItem
							key={text.id}
							text={text}
							index={index}
							open={open}
							idProp={text.id}
						/>
					))}
					{/* <HubspotChat drawerOpen={open} /> */}
				</List>
			</Drawer>
		</StrictMode>
	)
}

export default MBAIMainDrawer
