import React, {
	FC, ReactElement,
} from 'react'
import {
	AppBar,
	Box, Skeleton, Toolbar, Card,
	CardHeader, CardContent,
	Divider,
} from '@mui/material'
import BasicTextLoader from '../BasicTextLoader'

const AuthTemplateLoader: FC = (): ReactElement => (
	<Box
		data-testid="mbai-main-auth-template-loader"
	>
		<Box
			sx={{backgroundColor: 'background.default'}}
		>
			<AppBar
				position="static"
				sx={{
					backgroundColor: 'background.default',
					borderBottom: 1,
					borderColor: 'divider',
				}}
				data-testid="mbai-main-minimized-appbar"
			>
				<Toolbar>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<BasicTextLoader
							width={150}
						/>
						<Skeleton
							variant="rounded"
							sx={{
								width: '100px',
								height: '40px',
							}}
						/>
					</Box>
				</Toolbar>
			</AppBar>
			<Box>
				<Box
					sx={{
						pt: 5,
						pb: 2,
						px: 2,
					}}
				>
					<Card
						sx={{
							mx: 'auto',
							position: 'relative',
							width: '600px',
							maxWidth: '100%',
						}}
					>
						<CardHeader
							title={<BasicTextLoader width={350} />}
							sx={{
								borderBottom: 1,
								borderBottomColor: 'divider',
							}}
						/>
						<CardContent>
							<Box
								sx={{
									width: '100%',
									my: 2,
								}}
							>
								<Skeleton
									variant="rounded"
									sx={{
										width: '100%',
										height: '40px',
									}}
								/>
							</Box>
							<Divider />
							<Box
								sx={{
									width: '100%',
									mt: 2,
								}}
							>
								<Skeleton
									variant="rounded"
									sx={{
										width: '100%',
										height: '40px',
									}}
								/>
							</Box>
							<Box
								sx={{
									width: '100%',
									mt: 2,
								}}
							>
								<Skeleton
									variant="rounded"
									sx={{
										width: '100%',
										height: '40px',
									}}
								/>
							</Box>
							<Box
								sx={{
									width: '100%',
									mt: 2,
								}}
							>
								<Skeleton
									variant="rounded"
									sx={{
										width: '100%',
										height: '40px',
									}}
								/>
							</Box>
							<Box
								sx={{
									width: '100%',
									mt: 3,
								}}
							>
								<BasicTextLoader width={275} />
							</Box>
							<Box
								sx={{
									width: '100%',
									mt: 0.5,
								}}
							>
								<BasicTextLoader width={150} />
							</Box>
						</CardContent>
					</Card>
				</Box>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<BasicTextLoader
					width={100}
				/>
				<Box
					sx={{
						ml: 2, width: '150px',
					}}
				>
					<BasicTextLoader
						width={250}
					/>
				</Box>
			</Box>
		</Box>
	</Box>
)

export default AuthTemplateLoader
