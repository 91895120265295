import React, {
	FC, ReactElement,
} from 'react'
import {
	Box, Skeleton,
} from '@mui/material'
import BasicDashboardContainer from '../../BasicDashboardContainer'

const LineDashboardLoader: FC = (): ReactElement => (
	<BasicDashboardContainer>
		<Box
			data-testid="mbai-main-machine-dashboard-page-loader"
			sx={{
				width: '100%',
				height: '100vh',
			}}
		>
			<Box>
				<Skeleton
					sx={{
						width: '100%',
						maxWidth: '400px',
					}}
				/>
			</Box>
			<Box
				sx={{
					pt: 5,
					display: 'flex',
				}}
			>
				{
					[...Array(3)].map((item) => (
						<Skeleton
							key={item}
							variant="rounded"
							sx={{
								width: '200px',
								height: '24px',
								mx: 1,
							}}
						/>
					))
				}
			</Box>
			<Box
				sx={{
					pt: 3,
					height: 'calc(100vh - 165px)',
					display: 'flex',
				}}
			>
				<Skeleton
					variant="rounded"
					sx={{
						width: '100%',
						height: '100%',
					}}
				/>
				<Skeleton
					variant="rounded"
					sx={{
						width: 'calc(100% - 24px)',
						height: '100%',
						ml: 2,
					}}
				/>
			</Box>
		</Box>
	</BasicDashboardContainer>
)

export default LineDashboardLoader
