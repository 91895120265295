import {
	Component, ErrorInfo, ReactNode, ComponentType, ReactElement,
} from 'react'
import {
	Box, Button, Typography,
} from '@mui/material'
import {useCustomTranslation} from '../hooks/useCustomTranslation'
import {NavLink} from 'react-router-dom'
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'

const withTranslation = <T extends {}>(WrappedComponent: ComponentType<T & { t: (key: string) => string }>) => function (props: Omit<T, 't'>): ReactElement {
	const [t] = useCustomTranslation('errors')
	return <WrappedComponent {...(props as T)} t={t} />
}

interface Props {
    children: ReactNode
    fallbackComponent?: ReactNode
	height?: string
	t: (key: string) => string
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {hasError: false}
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('ErrorBoundary caught an error:', error, errorInfo)
		this.setState({hasError: true})
	}

	render() {
		const {hasError} = this.state
		const {
			children, fallbackComponent, t, height = 'calc(100vh - 65px)',
		} = this.props
		if (hasError) {
			// Render fallbackComponent if it exists, otherwise render a default message
			return fallbackComponent || (
				<Box
					sx={{
						height,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					data-testid="mbai-404-not-found-page"
				>
					<Box
						sx={{
							textAlign: 'center',
							mb: 10,
						}}
					>
						<ErrorTwoToneIcon
							sx={{
								fontSize: '10rem',
								color: 'primary.main',
							}}
						/>
						<Typography
							variant="body1"
							sx={{mt: 2}}
						>
							{t('error_boundary_generic')}
						</Typography>
						<Button
							variant="contained"
							color="primary"
							to="/"
							component={NavLink}
							sx={{
								mt: 2.5,
								textTransform: 'none',
								'&:hover': {color: 'text.primary'},
							}}
							endIcon={<ArrowForwardSharpIcon />}
						>
							{t('refresh')}
						</Button>
					</Box>
				</Box>
			)
		}

		return children
	}
}

export default withTranslation(ErrorBoundary)
