import React, {
	FC, ReactElement,
} from 'react'
import {LoadingButton} from '@mui/lab'
import {Typography} from '@mui/material'
import BasicModal from '../../components/BasicModal'
import {SnackbarPropsInterface} from '../../interfaces/notification'
import {useDispatch} from 'react-redux'
import {useUpdateUserMutation} from '../../../apis/user.ts'
import {useAuth} from '../../hooks/useAuth'
import {useSnackbar} from '../../hooks/useSnackbar'
import {useCustomTranslation} from '../../hooks/useCustomTranslation'
import {updateTutorial} from '../../../redux/slices/tutorial'

interface SkipTutorialProps {
	open: boolean
	onSkipTutorialClose: (both: boolean) => void
}

const SkipTutorial: FC<SkipTutorialProps> = ({
	open, onSkipTutorialClose,
}): ReactElement => {
	const [t] = useCustomTranslation('tutorial')
	const [updateUser, {isLoading}] = useUpdateUserMutation()
	const {updateLocalUser} = useAuth()
	const [showNotification] = useSnackbar()
	const dispatch = useDispatch()
	const handleSkiptutorial = () => {
		updateUser({onboarding_tutorial_skipped: true})
			.unwrap()
			.then((data) => {
				const successMessage: SnackbarPropsInterface = {
					message: t('tutorial_skipped'),
					severity: 'success',
				}
				showNotification(successMessage)
				updateLocalUser(data.user)
				dispatch(updateTutorial({
					tutorial_skipped: data.user.userprofile.onboarding_tutorial_skipped,
					last_step_completed: data.user.onboarding_tutorial_last_step_completed,
				}))
				onSkipTutorialClose(true)
			})
			.catch(() => {
				const errorObject: SnackbarPropsInterface = {
					message: 'Unexpected error!',
					severity: 'error',
				}
				showNotification(errorObject)
			})
	}

	return (
		<BasicModal
			open={open}
			onClose={() => onSkipTutorialClose(false)}
			title={t('skip_tutorial.title')}
			footerAction={(
				<LoadingButton
					data-cy="mbai-main-onboarding-tutorial-skip-button"
					variant="contained"
					onClick={handleSkiptutorial}
					loading={isLoading}
				>
					{t('skip')}
				</LoadingButton>
			)}
		>
			<Typography>
				{t('skip_tutorial.confirm_skip')}
			</Typography>
		</BasicModal>
	)
}

export default SkipTutorial
